<span class="badge badge-dot mr-4">

    <i *ngIf="status == 'COMPLETED'" class="bg-success"> </i>
    <app-loader-min *ngIf="status == 'IN_PROGRESS'" class="mr-2"></app-loader-min>
    <i *ngIf="status == 'PICHAT_PARTIALLY_TRAINED'" class="bg-info"> </i>
    <i *ngIf="status == 'PENDING'" class="bg-warning"> </i>
    <i *ngIf="status == 'ERROR'" class="bg-danger"> </i>

    <span *ngIf="status == 'COMPLETED'" class="status text-bold">{{'input_status.completed'|translate}}</span>
    <span *ngIf="status == 'PICHAT_PARTIALLY_TRAINED'" class="status">{{'input_status.pichat_partially_trained'|translate}}</span>
    <span *ngIf="status == 'IN_PROGRESS'" class="status">{{'input_status.in_progress'|translate}}</span>
    <span *ngIf="status == 'PENDING'" class="status">{{'input_status.missing'|translate}}</span>
    <span *ngIf="status == 'ERROR'" class="status">{{'input_status.error'|translate}}</span>

</span>
