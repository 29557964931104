import { Component, OnInit } from "@angular/core";

import Chart from "chart.js";
import {
  chartOptions,
  parseOptions,
} from "../../../utils/charts";
import { AdminService } from "src/app/services/admin.service";
import { Observable } from "rxjs";
import { User } from "src/app/models/user.model";
import { Store } from "@ngrx/store";
import { selectUser } from "src/app/store/auth/auth.selectors";
import { Router } from '@angular/router';
import { ApplicationPublic } from "src/app/models/application-public.model";
import { ApplicationService } from "src/app/services/application.service";
import { ApplicationReviewRequest } from "src/app/models/app-review-request.model";


@Component({
  selector: "app-admin-public",
  templateUrl: "admin.component.html",
  styleUrls: ["./admin.component.scss"]
})

export class AdminComponent implements OnInit {
  public dataAccount: any;
  public dataAccountDetails: any;
  public dataApplication: any;
  public dataPieApplication: any;
  public labelsApplication: any;
  public colorsApplication: any;
  user$: Observable<User>;

  publicationRequestedApps: ApplicationPublic[] = [];

  refusalReason: string;

  constructor(
    private router: Router, private store: Store,private adminService: AdminService, private applicationService: ApplicationService) {}

  ngOnInit() {
    parseOptions(Chart, chartOptions());
    this.getData();

  }

  private getData() {
    this.user$ = this.store.select(selectUser);
    this.user$.subscribe(user => {
      if(user.role != 'ROLE_ADMIN') {
        this.router.navigate(['/dashboard','applications']);
      }
      // accounts
      this.adminService.generateAccountKpi(user.id).subscribe((data) => {
        this.dataAccount = data;
      });

      // accounts details
      this.adminService.generateAccountDetailsKpi(user.id).subscribe((data) => {
        this.dataAccountDetails = data;
      });

      // accounts
      this.adminService.generateApplicationKpi(user.id).subscribe((data) => {
        this.dataApplication = data;
        this.dataPieApplication = [data.numberOfPiFunction,data.numberOfPiProfile, data.numberOfPiTransfer, data.numberOfPiChat]
        this.labelsApplication = ['piFunction', 'piProfile', 'piTransfer', 'piChat']
        this.colorsApplication = ['#FB9C4F', '#9d4edd', '#8F2D56 ', 'black']
      });

      this.getPublicationRequests();
    });
  }

  getPublicationRequests() {
    this.applicationService.getAppsWithPublicationRequest().subscribe((applications) => {
      this.publicationRequestedApps = applications;
    });
  }

  acceptReview(applicationId: string) {
    const reviewRequest: ApplicationReviewRequest = {
      applicationId,
      accepted: true
    }

    console.log(reviewRequest);

    this.applicationService.reviewApplicationPublicationRequest(reviewRequest).subscribe(_ => {
      this.getPublicationRequests();
    })
  }

  refuseReview(applicationId: string) {
    const reviewRequest: ApplicationReviewRequest = {
      applicationId,
      accepted: false,
      reason: this.refusalReason
    }

    this.applicationService.reviewApplicationPublicationRequest(reviewRequest).subscribe(_ => {
      this.getPublicationRequests();
    })
  }
}
