
<span *ngIf="fileOrFolder" id="{{ fileOrFolder.id }}" [ngClass]="isActiv ? 'd-flex align-items-center justify-content-between activ bg-pi-blue-fluo' : 'd-flex align-items-center justify-content-between'">
  <!-- todo jk ang17 "dropAndUpload($event)  -> "dropAndUpload()
  ✘ [ERROR] NG4: Expected 0 arguments, but got 1. [plugin angular-compiler]

    src/app/pages/admin/application/components/filesystem-folder/filesystem-folder.component.html:3:80:
      3 │ ...f="isAFolder" dropZoneLabel="" (onFileDrop)="dropAndUpload($event)"
  -->
  <ngx-file-drop *ngIf="isAFolder" dropZoneLabel="" (onFileDrop)="dropAndUpload($event)"
  (onFileOver)="fileOver($event, 'row')" dropZoneClassName="ngx-file-drop-style" contentClassName="clean_nativ_css">
      <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector" class="filetext">
        <div id="{{ fileOrFolder.path }}" class="ellipsis" title="{{ fileOrFolder.key }}">
          <i class="text-pi-blue-light ni ni-folder-17 mx-2 my-2"></i>
          {{ fileOrFolder.key_decoded }}
        </div>
      </ng-template>
  </ngx-file-drop>
  <div *ngIf="!isAFolder && fileOrFolder.key" class="filetext">
    <div id="{{ fileOrFolder.path }}" class="ellipsis" title="{{ fileOrFolder.key }}">
      <i class="fas fa-file mx-2 my-2"></i>
      {{ fileOrFolder.key_decoded }}
    </div>
  </div>
</span>
