
<div class="card border">
  <div class="card-header border-0">
    <div class="row align-items-center">
      <div class="col-md-8"><h2 class="text-center text-md-left">  {{'applc.h1'|translate}} <app-micro-app></app-micro-app></h2></div>

      <div class="col-md-4 d-flex justify-content-around justify-content-md-end">
        <span class="btn btn-sm btn-light text-primary pointer my-2" (click)="this.ngOnInit();">
          {{'applc.h2'|translate}}
        </span>
        <span class="btn btn-sm btn-primary text-white pointer d-none d-md-inline-block my-2" [routerLink]="['/order']">
            {{'applc.h9'|translate}}
        </span>
        <span class="btn btn-sm btn-primary text-white pointer d-inline-block d-md-none my-2" [routerLink]="['/order']">
          {{'applc.create_short'|translate}}
        </span>
      </div>
    </div>
  </div>

  <div class="table-responsive tableFixHead bg-light" style="height: 500px;">
    <table class="table align-items-center table-flush pl-4">
      <thead class="thead-light">
        <tr>
          <th scope="col" style="width: 30%;">{{'applc.h3'|translate}}</th>

          <th scope="col" style="width: 30%;">{{'applc.h4'|translate}}</th>

          <th scope="col" style="width: 30%;">{{'applc.h5'|translate}}</th>

          <th scope="col"></th>
        </tr>
      </thead>
      <tbody *ngIf="applications" class="list" >
        <tr *ngFor="let application of applications" class="bg-white">
          <td>
            <div class="media align-items-center">
              <div class="media-body">
                <a [routerLink]="['/dashboard/applications', application.id , 'overview']">
                  <span >
                    <p class="my-0 display-6 link-custom">
                      {{ application.name.length > 25 ? application.name.substring(0,25) + '..' : application.name }}
                      </p>
                  </span>
                </a>
              </div>
            </div>
          </td>
          <td>
            <div>
              <img src="assets/templates/{{application.template.name}}/text_color.png" style="height: 15px;">
            </div>
          </td>
          <td>
            <div class="media align-items-center">
              <span  *ngIf="application?.state!='UP'" class="badge badge-dot mr-4">
                <i class="bg-warning"> </i>
                <span class="status"> {{'billing.'+(application.state )|translate}}</span>
              </span>

              <a *ngIf="application?.state=='UP'" href="{{ 'https://' + application.domain.url }}" target="_blank">
                <i class="ni ni-button-play text-success lead my-0"></i>
              </a>
            </div>
          </td>

          <td class="text-right">
            <div dropdown class="dropdown no-caret">
              <a
                id="button-basic"
                dropdownToggle
                role="button"
                class="btn btn-sm btn-icon-only text-primary dropdown-toggle"
                aria-controls="dropdown-basic"
              >
                <i class="fas fa-ellipsis-v"> </i>
              </a>

              <div
                class="dropdown-menu dropdown-menu-right dropdown-menu-arrow"
                *dropdownMenu
              >
                <a class="dropdown-item" [routerLink]="['/dashboard/applications', application.id, 'overview']">
                  {{'applc.h7'|translate}}
                </a>
                <a class="dropdown-item" [routerLink]="['/dashboard/applications', application.id, 'configuration']">
                  {{'applc.h8'|translate}}
                </a>
              </div>
            </div>
          </td>
        </tr>

        <tr *ngIf="applications?.length != 0" class="thead-light bg-light" style="height: 100px;">
          <th scope="row" class="thead-light"></th>
          <th scope="row" class="thead-light"></th>
          <th scope="row" class="thead-light"></th>
          <th scope="row" class="thead-light"></th>
        </tr>
      </tbody>
    </table>

    <div *ngIf="applications?.length == 0" class="table-responsive">
      <table class="table align-items-center table-flush text-center my-6">
        <h3>{{'applc.h10'|translate}}</h3>
      </table>
    </div>
  </div>
</div>
