
<div class=""(window:resize)="isMobile($event)">
  <app-dashboard-sidebar *ngIf="isApplication" [ngClass]="{ 'sidenav fixed-left': isMobileResolution === false }"></app-dashboard-sidebar>
  <div class="main-content d-flex flex-column">

    <app-dashboard-navbar></app-dashboard-navbar>
    <div class="bg-white h-100 container" >
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>
</div>
