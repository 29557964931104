<nav
  id="navbar-main" class="navbar navbar-top navbar-expand navbar-dark border-bottom bg-primary">
  <div class="container-fluid">
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <div class="d-flex flex-row align-items-center">
        <div>
          <a [routerLink]="['/']">
            <img src="../../../../assets/img/logo/logo_white.png" width="100px"/>
          </a>
        </div>
        <div>
          <small *ngIf="isSubscribedToPirogPro" class="text-white p-1"><strong>PRO</strong></small>
        </div>

      </div>
      <!-- Search form -->
      <!-- <form
        class="navbar-search navbar-search-light form-inline mr-sm-3"
        id="navbar-search-main"
      >
        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"
                ><i class="fas fa-search"></i
              ></span>
            </div>
            <input
              class="form-control"
              placeholder="Search"
              type="text"
              (focus)="focus = true"
              (blur)="focus = false"
            />
          </div>
        </div>
        <button
          type="button"
          class="close"
          (click)="closeSearch()"
          data-action="search-close"
          data-target="#navbar-search-main"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </form> -->
      <!-- Navbar links -->
      <ul class="navbar-nav align-items-center justify-content-between ml-md-auto">

        <!-- Sidenav toggler -->
        <!--
        <li>ok</li>
        <li *ngIf="isConsole == false" class="nav-item d-xl-none">

          <div
            class="pr-3 sidenav-toggler sidenav-toggler-dark"
            data-action="sidenav-pin"
            data-target="#sidenav-main"
            (click)="openSidebar()"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li> -->
        <!-- <li class="nav-item d-sm-none">
          <a class="nav-link" (click)="openSearch()">
            <i class="ni ni-zoom-split-in"></i>
          </a>
        </li> -->

        <!--
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link dropdown-toggle" role="button" dropdownToggle>
            <i class="ni ni-bell-55"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-xl dropdown-menu-right py-0 overflow-hidden"
            *dropdownMenu
          >
            <div class="px-3 py-3">
              <h6 class="text-sm text-muted m-0">
                You have <strong class="text-primary">13</strong> notifications.
              </h6>
            </div>
            <div class="list-group list-group-flush">
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-1.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snows</h4></div>
                      <div class="text-right text-muted">
                        <small>2 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Let's meet at Starbucks at 11:30. Wdyt?
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-2.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>3 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      A new issue has been reported for Argon.
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-3.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>5 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Your posts have been liked a lot.
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-4.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>2 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      Let's meet at Starbucks at 11:30. Wdyt?
                    </p>
                  </div>
                </div>
              </a>
              <a
                href="javascript:void()"
                class="list-group-item list-group-item-action"
              >
                <div class="row align-items-center">
                  <div class="col-auto">
                    <img
                      alt="Image placeholder"
                      src="assets/img/theme/team-5.jpg"
                      class="avatar rounded-circle"
                    />
                  </div>
                  <div class="col ml--2">
                    <div
                      class="d-flex justify-content-between align-items-center"
                    >
                      <div><h4 class="mb-0 text-sm">John Snow</h4></div>
                      <div class="text-right text-muted">
                        <small>3 hrs ago</small>
                      </div>
                    </div>
                    <p class="text-sm mb-0">
                      A new issue has been reported for Argon.
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <a
              href="javascript:void()"
              class="dropdown-item text-center text-primary font-weight-bold py-3"
              >View all</a
            >
          </div>
        </li>-->

      </ul>
      <!-- User -->
      <ul class="navbar-nav align-items-center ml-auto ml-md-0">
        <li *ngIf="!isConsole" class="nav-item mr-5" dropdown >
          <a class="btn btn-sm btn-secondary" role="button" [routerLink]="['/dashboard/applications']">
            {{'navbaradmin.myapps'|translate}}
          </a>
        </li>
        <li class="nav-item">
          <pirog-pro-button  [gold]="true" [iconMode]="false" [currentStepRecap]="'description'"></pirog-pro-button>
        </li>

        <!-- <li class="nav-item dropdown mx-sm-2" dropdown placement="bottom-right">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" dropdownToggle>
            <i class="ni ni-ungroup"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right" *dropdownMenu>
            <div class="row shortcuts px-4">
              <a class="col-4 shortcut-item" [routerLink]="['/store/']">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-orange"
                >
                  <i class="ni ni-app"></i>
                </span>
                <small>{{'navbaradmin.store'|translate}}</small>
              </a>
             <a class="col-4 shortcut-item" [routerLink]="['/doc/']">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-green"
                >
                  <i class="ni ni-books"></i>
                </span>
                <small>{{'navbaradmin.docs'|translate}}</small>
              </a>
              <a class="col-4 shortcut-item" href="{{stripeUrl}}"target="_blank">
                <span
                  class="shortcut-media avatar rounded-circle bg-gradient-danger"
                >
                  <i class="ni ni-credit-card"></i>
                </span>
                <small>{{'navbaradmin.billing'|translate}}</small>
              </a>
            </div>
          </div>
        </li> -->
        <li *ngIf="isApplication" class="nav-item d-xl-none mx-sm-2">

          <div
            class="pl-3 sidenav-toggler sidenav-toggler-dark"
            data-action="sidenav-pin"
            data-target="#sidenav-main"
            (click)="openSidebar()"
          >
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown m-auto mr-sm-2" dropdown placement="bottom-right">
          <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
            <div class="media align-items-center">
              <span>
                <app-profile-image avatarSize="sm" [showName]="false"></app-profile-image>
              </span>
            </div>
          </a>
          <div
            class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" *dropdownMenu>
            <div class="dropdown-header noti-title">
              <h6 class="text-overflow m-0">{{'navbaradmin.welcome'|translate}}</h6>
            </div>
            <a routerLinkActive="active" [routerLink]="['/dashboard/account']" class="dropdown-item">
              <i class="ni ni-single-02"></i> <span>{{'navbaradmin.account'|translate}}</span>
            </a>
            <a routerLinkActive="active" href="{{stripeUrl}}" target="_blank" class="dropdown-item">
              <i class="ni ni-settings-gear-65"></i> <span>{{'navbaradmin.billing'|translate}}</span>
            </a>
            <!-- <a routerLinkActive="active" [routerLink]="['/store']" class="dropdown-item">
              <i class="ni ni-app"></i> <span>{{'navbaradmin.store'|translate}}</span>
            </a> -->
            <a *ngIf="(user$ | async)?.role == 'ROLE_ADMIN'" routerLinkActive="active" [routerLink]="['/dashboard/admin']" class="dropdown-item">
              <i class="ni ni-key-25"></i> <span>ADMIN</span>
            </a>
            <div class="dropdown-divider"></div>
            <a (click)="logout()" class="dropdown-item">
              <i class="ni ni-user-run"></i> <span>{{'navbar.logout'|translate}}</span>
            </a>
          </div>
        </li>
      </ul>

    </div>
  </div>
</nav>
