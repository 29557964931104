import {Routes} from "@angular/router";
import {ApplicationsComponent} from "./applications/applications.component";
import {ApplicationOverviewComponent} from "./application/application-overview/application-overview.component";
import {PiAnalyticsComponent} from "./application/pianalytics/pianalytics.component";
import {
  ApplicationConfigurationComponent
} from "./application/application-configuration/application-configuration.component";
import {PublicOverviewComponent} from "./application/public-overview/public-overview.component";
import {AccountComponent} from "./account/account.component";
import {ApplicationLayoutComponent} from "./application/application-layout.component";
import {AdminComponent} from "./admin/admin.component";


export const dashboardRoutes: Routes = [
  {path: '', redirectTo: 'applications', pathMatch: 'full'},
  {
    path: 'applications',
    title: 'Applications',
    component: ApplicationsComponent
  },
  {
    path: 'applications/:applicationId',
    component: ApplicationLayoutComponent,
    children: [
      {path: '', redirectTo: 'overview', pathMatch: 'full'},
      {
        path: 'overview',
        component: ApplicationOverviewComponent,
      },
      {
        path: 'pianalytics',
        component: PiAnalyticsComponent,
      },
      {
        path: 'configuration',
        component: ApplicationConfigurationComponent,
      },
      {
        path: 'public',
        component: PublicOverviewComponent,
      },
    ]
  },
  {
    path: 'account',
    title: 'Account',
    component: AccountComponent
  },
  {
    path: 'admin',
    title: 'Admin',
    component: AdminComponent
  }
];
