import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { UpdateAccountRequest } from '../models/update-account-request.model';
import { Kpi, Timeseries } from '../models/analytics.model';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    constructor(private httpClient: HttpClient) {}

    public getKpiByApplication(id: string) {
        return this.httpClient.get<Kpi>(`analytics/kpi/${id}`);
    }

    public getTimeseriesByApplication(id: string) {
        return this.httpClient.get<Timeseries[]>(`analytics/timeseries/${id}`);
    }
}