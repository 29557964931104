
  <div class="card card-stats">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <h5 class="card-title text-uppercase text-muted mb-0">
            {{ title }}
          </h5>

          <span class="h2 font-weight-bold mb-0"> {{ value }} </span>
        </div>

        <div class="col-auto">
          <div
           class="icon icon-shape text-white rounded-circle shadow"
           [ngClass]="class"
           >
            <i [ngClass]="icon"> </i>
          </div>
        </div>
      </div>

      <p class="mt-3 mb-0 text-sm">
        <span *ngIf="delta[0] == '-'" class="text-danger mr-2">
          <i class="fa fa-arrow-down"> </i> {{ delta }}
        </span>

        <span *ngIf="delta[0] != '-'" class="text-success mr-2">
          <i class="fa fa-arrow-up"> </i> {{ delta }}
        </span>

        <span class="text-nowrap"> Since last month </span>
      </p>
    </div>
  </div>
