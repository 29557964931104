import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-pi-chat',
  templateUrl: './pi-chat.component.html',
  styleUrls: ['./pi-chat.component.scss']
})
export class PiChatComponent implements OnInit {
  @Input() application: Application;
  @Input() templateRequests:  ApplicationTemplateRequest[];
  @Input() getData : any;
  
  @Output() templateRequestsChange = new EventEmitter<boolean>();
  @ViewChild('requestsModal', { read: TemplateRef }) requestsModal:TemplateRef<any>;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };
  selectTemplateModalSettings = {
    keyboard: true,
    class: "modal-xl"
  };
  
  openRequestModalSubject: Subject<void> = new Subject<void>();
  selectedTemplateRequests: ApplicationTemplateRequest[];
  welcome_message:string;
  selectedSubCategory: string = "";
  userName:string;
  robotName:string;
  userImage:string;
  robotImage:string;
  pageBuilderUrl :string = environment.PAGE_BUILDER_URL;
  constructor(   private modalService: BsModalService,) { }

  ngOnInit(): void {
    this.welcome_message =this.getTemplateRequestsValueByName("robotWelcomeMessage");
    this.userName=this.getTemplateRequestsValueByName("userName");
    this.robotName=this.getTemplateRequestsValueByName("robotName");
    this.userImage=this.getTemplateRequestsValueByName("userImage");
    this.robotImage=this.getTemplateRequestsValueByName("robotImage");
  }

  getTemplateRequests(subCategory: string): ApplicationTemplateRequest[] {
    return this.templateRequests.filter(a => (a.templateRequest.subCategory === subCategory && a.templateRequest.pageBuilder));
  }
  getTemplateRequestsValueByName(name: string): string {
    return this.templateRequests.filter(a => (a.templateRequest.name === name ))[0].value;
  }

  openRequestsModal(subCategory: string) {
    this.selectedSubCategory = subCategory;
    this.formModal = this.modalService.show(this.requestsModal, this.selectTemplateModalSettings);
  }
}
