<div class="col-12 position-relative bg-primary custom-greenrectangle ">  
    <div class="d-flex ">
        <div class="logo btn-danger" (click)="closeDefaultModal()"></div> 
        <div class="logo bg-warning"></div>
        <div class="logo bg-success"></div>
    </div>
    <div class="custom-rectangle bg-white mt-2 position-absolute d-flex align-items-center">
        <span class="ml-3">
            <i class="mr-1  fa-solid fa-lock"></i> 
            https://<span class="font-weight-bold gray-600">{{ applicationName }}</span>.pirog.io</span>  
    </div>
</div>