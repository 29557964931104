<div *ngIf="(isSubscribedToPirogPro$ | async); else freemium ">
  <div *ngIf="(application$ | async)?.publicationStatus ==='REQUESTED'" class="">
  <div class="m-4">
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-10 m-auto">
            <h1> {{ 'public_overview.request_sent' | translate }}  <i class="ni ni-spaceship ml-2"></i></h1>
            <h4 class="font-weight-light">{{ 'public_overview.will_receive_notification' | translate }}</h4>
          </div>
          <div class="col-md-2 m-auto">
            <img src="../../../../../../assets/img/infography/connect.png" class="img img-responsive w-100">
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div *ngIf="(application$ | async)?.publicationStatus ==='REVIEWING'" class="">
  <div class="m-4">
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-center">
          <div class="col-md-10">
            <h1> {{ 'public_overview.request_under_study' | translate }} <i class="ni ni-spaceship ml-2"></i></h1>
            <h4 class="font-weight-light">{{ 'public_overview.will_receive_notification' | translate }}</h4>
          </div>
          <div class="col-md-2 m-auto">
            <img src="../../../../../../assets/img/infography/connect.png" class="img img-responsive w-100">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(application$ | async)?.publicationStatus ==='PUBLISHED'" class="container mt-5">
  <div class="container-fluid mt--4">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="row">
              <div class="col">
                <h1 class="p-3">{{ 'public_overview.pirog_explorer_settings' | translate }}</h1>
              </div>
            </div>

          </div>
          <div class="col-md-12">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-md-6">
                    <h5 class="card-title text-uppercase text-muted mb-0">{{ 'public_overview.application_status' | translate }}</h5>
                    <span class="h2 font-weight-bold mb-0"> {{ 'public_overview.status_' + (application$ | async)?.publicationStatus | translate }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="card">
              <div class="card-body">
                  <h2 class="surtitle text-primary">{{ 'public_overview.public_headline' | translate }} <a class="btn btn-primary btn-sm ml-1" (click)="openModal(headlineForm)"><i class="fas fa-edit text-white"></i></a></h2>
                  <h3>{{ (application$ | async).publicHeadline }}</h3>
                    <h2 class="surtitle text-primary mt-4">{{ 'public_overview.description' | translate }} <a class="btn btn-primary btn-sm ml-1" (click)="openModal(descriptionForm)"><i class="fas fa-edit text-white"></i></a></h2>
                    <p>{{ (application$ | async).publicDescription }}</p>

                  <h2 class="surtitle text-primary mt-4">{{ 'public_overview.screenshot' | translate }} <a class="btn btn-primary btn-sm ml-1" (click)="openModal(screenshotForm)"><i class="fas fa-edit text-white"></i></a></h2><br/>
                  <img class="w-50 seo_ratio_16_9 text-center" src="{{ (application$ | async).publicImageUrl }}">
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-wrap justify-content-between align-items-center">
                  <div class="col-md-6">
                    <h2 class="text-left mb-2">{{'cc.advanced' | translate}}</h2>

                    <p>{{ 'public_overview.remove_from_explorer' | translate }}</p>
                  </div>
                  <div class="col-md-6 text-center text-md-right">
                    <button class="btn btn-white border-danger text-danger" type="button"
                    (click)="unpublishApplication()">{{'remove' | translate}}
                    </button>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>



<div class="container mt-4 mb-4" *ngIf="(application$ | async)?.publicationStatus ==='NONE'">
      <div class="m-4">
        <div class="card">
          <div class="card-body">
            <div class="row justify-content-center">
              <div class="col-md-10">
                <h1> <i class="ni ni-spaceship mr-2"></i>{{ 'public_overview.unlock_opportunities_main_title' | translate }} {{ (application$ | async)?.name }} ! </h1>
                <h4 class="font-weight-light">{{ 'public_overview.unlock_opportunities_main_description' | translate }}</h4>
                <ul>
                    <li class="h4 font-weight-light">
                        &nbsp; {{ 'public_overview.unlock_opportunities_first_element' | translate }}
                    </li>
                    <li class="h4 font-weight-light">
                        &nbsp; {{ 'public_overview.unlock_opportunities_second_element' | translate }}
                    </li>
                </ul>
              </div>
              <div class="col-md-2 m-auto">
                <img src="../../../../../../assets/img/infography/connect.png" class="img img-responsive w-100">
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="m-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <h2>{{ 'public_overview.flurishing_community_title' | translate }}</h2>
                <h4 class="font-weight-light">{{ 'public_overview.flurishing_community_description' | translate }}</h4>
              </div>
              <div class="m-autp">
                <button class="btn btn-secondary text-right" target="_blank" (click)="redirectToExplorePage()">Accéder au store</button>
              </div>
            </div>
          </div>
        </div>
  </div>

      <div class="m-4">

        <form [formGroup]="applicationFormGroup" (ngSubmit)="onSubmit()">
          <div class="form-group mb-3">

            <div class="card">
              <div class="card-header">
                <div class="row align-items-center">

                  <div class="col-12">   <h2>{{ 'public_overview.how_to_public' | translate }}</h2>
                    <h4 class="font-weight-light">{{ 'public_overview.how_to_public_description' | translate }}</h4></div>
                </div>
              </div>

              <div class="card-body">


                  <h6 class="heading-small text-muted mb-4">{{ 'public_overview.application_details' | translate }}</h6>

                  <div class="pl-lg-4">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label" for="input-address">
                            {{ 'public_overview.public_headline' | translate }}
                          </label>

                          <input
                            class="form-control"
                            id="input-address"
                            type="text"
                            [formControl]="publicHeadlineCtrl"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-control-label"> {{ 'public_overview.description' | translate }} </label>

                          <textarea
                            class="form-control"
                            rows="2"
                            [formControl]="publicDescriptionCtrl"
                          >

                      </textarea>
                        </div>
                      </div>
                    </div>
                    <label class="form-control-label"> {{ 'public_overview.screenshot' | translate }} </label>
                    <div class="dropzone dropzone-single mb-3" id="dropzone-single">
                      <div class="fallback">
                        <div class="custom-file">
                          <input
                            class="custom-file-input"
                            id="projectCoverUploads"
                            type="file"
                            accept="image/*"
                            (change)="onFileChange($event)"
                          />

                          <label class="custom-file-label" for="projectCoverUploads">
                            {{ 'public_overview.choose_file' | translate }}
                          </label>
                        </div>
                      </div>

                      <div class="dz-preview dz-preview-single">
                        <div class="dz-preview-cover">
                          <img
                            alt="..."
                            class="dz-preview-img"
                            data-dz-thumbnail=""
                            src="..."
                          />
                        </div>
                      </div>
                    <p *ngIf="screenshotImage"> {{ screenshotImage.name }} <span class="green">✓</span></p>

                    </div>
                  </div>

                  <div class="row d-flex justify-content-end">
                    <button class="btn btn-primary mt-2" type="submit">
                      {{ 'public_overview.send_request' | translate }} <i class="ni ni-spaceship ml-2"></i>
                    </button>
                  </div>



              </div>
            </div>

          </div>

        </form>
      </div>
</div>

<ng-template #headlineForm>
  <div class="modal-body p-0">
    <div class="card border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">
         <h3 class="text-center mb-2">{{ 'public_overview.update_headline' | translate }}</h3>
          <div
            class="form-group mb-3 mt-4">
            <div class="input-group input-group-alternative">
              <input
                class="form-control"
                placeholder="{{ 'public_overview.public_headline' | translate }}"
                type="text"
                [(ngModel)]="updatedHeadline"
              />
            </div>
          </div>

          <div class="text-center">
            <button type="button" (click)="updateHeadline()" class="btn btn-primary my-4">
              Update
            </button>
          </div>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #descriptionForm>
  <div class="modal-body p-0">
    <div class="card border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">
         <h3 class="text-center mb-2">{{ 'public_overview.update_description' | translate }}</h3>
          <div class="form-group mb-3 mt-4">
            <div class="input-group input-group-alternative">
              <textarea
                class="form-control"
                placeholder="Description"
                type="text"
                [(ngModel)]="updatedDescription">
              </textarea>
            </div>
          </div>

          <div class="text-center">
            <button type="button" (click)="updateDescription()" class="btn btn-primary my-4">
              Update
            </button>
          </div>

      </div>
    </div>
  </div>
</ng-template>

<ng-template #screenshotForm>
  <div class="modal-body p-0">
    <div class="card border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">
         <h3 class="text-center mb-2">{{ 'public_overview.update_screenshot' | translate }}</h3>
          <div class="form-group mb-3 mt-4">
            <div class="input-group input-group-alternative">
              <input
              class="custom-file-input"
              id="projectCoverUploads"
              type="file"
              accept="image/*"
              (change)="onFileChange($event)"
            />
            </div>
            <p *ngIf="screenshotImage"> {{ screenshotImage.name }} <span class="green">✓</span></p>
          </div>

          <div class="text-center">
            <button type="button" (click)="updateScreenshot()" class="btn btn-primary my-4">
              Update
            </button>
          </div>

      </div>
    </div>
  </div>
</ng-template>
</div>
<!-- Pirog Freemium -->
<ng-template #freemium>
  <div class="container-fluid">
    <div class="card-deck flex-column flex-xl-row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h1>{{ 'public_overview.flurishing_community_title' | translate }}</h1>
            <p class="lead">{{ 'public_overview.flurishing_community_description' | translate }}</p>
            <ul>
                <li class="h4 font-weight-light">
                    &nbsp; <strong>{{ 'public_overview.unlock_opportunities_first_element' | translate }}</strong>
                </li>
                <li class="h4 font-weight-light">
                    &nbsp; <strong>{{ 'public_overview.unlock_opportunities_second_element' | translate }}</strong>
                </li>
            </ul>
          </div>
          <div class="text-center">
            <pirog-pro-button [gold]="true" [buttonText]="'pirog_pro.button.free' | translate" [currentStepRecap]="'terms'" [iconMode]="false"></pirog-pro-button>
            <pirog-pro-button [buttonText]="'pirog_pro.button.plus' | translate" [currentStepRecap]="'description'" [iconMode]="false"></pirog-pro-button>
          </div>
          <br><br>
        </div>
      </div>
    </div>
  </div>
</ng-template>
