import { Component, OnInit, OnDestroy, Input, TemplateRef } from "@angular/core";
import { Observable, Subscription } from 'rxjs';
import { Application } from 'src/app/models/application.model';
import { Store } from '@ngrx/store';
import { selectCurrentApplication, selectCurrentApplicationIsConfigComplete } from 'src/app/store/repository/repository.selector';
import { selectIsUserSubscribedToPirogPro } from 'src/app/store/auth/auth.selectors';
import { SeoService } from "src/app/services/seo.service";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "src/app/services/application.service";
import { PublicationRequest } from "src/app/models/publication-request.model";
import swal from "sweetalert2";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { S3ServiceService } from "src/app/services/s3-service.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { UpdatePublicInfo } from "src/app/models/update-public-info.model";
import { Router } from "@angular/router";

@Component({
  selector: "app-public-overview",
  templateUrl: "public-overview.component.html",
  styleUrls: ['public-overview.component.scss']
})

export class PublicOverviewComponent implements OnInit {
  application$: Observable<Application>;
  isConfigComplete$: Observable<boolean>;
  isSubscribedToPirogPro$: Observable<boolean>;

  subscriptions: Subscription = new Subscription();
  application: Application;
  screenshotsBaseUrl: string;
  bucketName: string;

  applicationFormGroup: FormGroup;
  screenshotImage: any;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };

  updatedHeadline: string;
  updatedDescription: string;

  constructor(
    private store: Store,
    private seoService: SeoService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private storageService: S3ServiceService,
    private applicationService: ApplicationService,
    public router: Router,
    private translateService: TranslateService) { }


  ngOnInit() {
    this.bucketName = environment.SHARED_FRONTS_BUCKET;
    this.seoService.setSeoTags({ titleKey: "seo.pianalytics.title", descriptionKey: "seo.pianalytics.description", noindex: true });
    
    this.applicationFormGroup = this.fb.group({
      publicHeadline: ['', Validators.required],
      publicDescription: ['', Validators.required],
      publicImage: ['', Validators.required]
    });

    this.initData();
  }

  private initData(): void {
    this.application$ = this.store.select(selectCurrentApplication);
    this.application$.subscribe(
      response => {
        this.application = response;
        this.screenshotsBaseUrl = `${environment.S3_PREFIXE}/${response.id}/img/publicScreenshot/`
      })

    this.isConfigComplete$ = this.store.select(selectCurrentApplicationIsConfigComplete);
    this.isSubscribedToPirogPro$ = this.store.select(selectIsUserSubscribedToPirogPro);
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      this.screenshotImage = event.target.files[0];

      this.applicationFormGroup.patchValue({
        publicImage: this.screenshotImage.name
      });
    }
  }

  openModal(modalForm: TemplateRef<any>) {
    this.formModal = this.modalService.show(modalForm, this.form);
  }

  async onSubmit() {
    if (this.applicationFormGroup.valid) {

      var uploadUrl = this.screenshotsBaseUrl + this.applicationFormGroup.get('publicImage').value;

      await this.storageService.uploadFile(this.screenshotImage, uploadUrl, this.bucketName);

      var request: PublicationRequest = {
        applicationId: this.application.id,
        headline: this.applicationFormGroup.get('publicHeadline').value,
        description: this.applicationFormGroup.get('publicDescription').value,
        publicImageUrl: uploadUrl
      }

      this.applicationService.requestPublication(request).subscribe(_ => {
        location.reload()
      });
    }
  }

  updateHeadline() {
    var request: UpdatePublicInfo = {
      applicationId: this.application.id,
      headline: this.updatedHeadline
    }

    this.applicationService.updatePublicInfo(request).subscribe(_ => {
      location.reload()
    });
  }

  updateDescription() {
    var request: UpdatePublicInfo = {
      applicationId: this.application.id,
      description: this.updatedDescription
    }

    this.applicationService.updatePublicInfo(request).subscribe(_ => {
      location.reload()
    });
  }

  redirectToExplorePage() {
    this.router.navigate(['/explore']);
  }

  async updateScreenshot() {
    var uploadUrl = this.screenshotsBaseUrl + this.applicationFormGroup.get('publicImage').value;

    await this.storageService.uploadFile(this.screenshotImage, uploadUrl, this.bucketName);

    var request: UpdatePublicInfo = {
      applicationId: this.application.id,
      publicImageUrl: uploadUrl
    }

    this.applicationService.updatePublicInfo(request).subscribe(_ => {
      location.reload()
    });
  }

  get publicHeadlineCtrl(): FormControl {
    return this.applicationFormGroup.get('publicHeadline') as FormControl;
  }

  get publicDescriptionCtrl(): FormControl {
    return this.applicationFormGroup.get('publicDescription') as FormControl;
  }

  unpublishApplication() {
    swal.fire({
      title: this.translateService.instant("public_overview.removal_modal.title"),
      text: this.translateService.instant("public_overview.removal_modal.text"),
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-primary"
      },
      confirmButtonText: this.translateService.instant("public_overview.removal_modal.confirmButtonText"),
      cancelButtonText: this.translateService.instant("public_overview.removal_modal.cancelButtonText"),
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        this.applicationService.unpublishApplicationById(this.application.id).subscribe(_ => {
          location.reload()
        })
      }
    });
  }
}
