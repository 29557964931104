<div class="card flex-column d-flex justify-content-around" style="height: 95%;">
  <div class="card-header bg-transparent">
    <h2 class="h3 mb-0">{{'pkc.' + input?.title|translate}}</h2>
  </div>
  <div class="card-header bg-transparent">
    <span class="font-weight-bold mb-0 kpi"> {{ input?.total ? input?.total : 0 }} </span>
    <div class="text-nowrap"> {{'pkc.h1'|translate}}</div>
    <div *ngIf="input.total_delta" [ngClass]="input?.total_delta >= 0 ? 'text-success mr-2'  : 'text-danger mr-2'">
      <i [ngClass]="input?.total_delta >= 0 ? 'fa fa-arrow-up' : 'fa fa-arrow-down'"> </i>
      {{ input?.total_delta }}%
    </div>
  </div>


  <!-- <div class="card-header border-0">
    <div class="row align-items-center">
      <div class="col"><h3 class="mb-0">{{'pkc.h2'|translate}}</h3></div>
    </div>
  </div> -->

  <div class="table">
    <table class="table align-items-center table-flush">
      <thead class="thead-light">
        <tr>
          <th scope="col">{{'pkc.h3'|translate}}</th>
          <th scope="col">{{'pkc.volume'|translate}}</th>
          <th scope="col">{{'pkc.h5'|translate}}</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <th scope="row">{{'pkc.h6'|translate}}</th>
          <td class="text-center">{{ input?.total_desktop ? input?.total_desktop : 0 }}</td>
          <td>
            <div class="d-flex align-items-center">
              <span class="fix-size-custom"> {{ input?.percent_desktop ? input?.percent_desktop  + '%': '0 %'}} </span>
              <!-- <div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="input?.percent_mobile"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [style.background-color]="input?.desktopColor"
                    [style.width]="input?.percent_desktop + '%'"
                  ></div>
                </div>
              </div> -->
            </div>
          </td>
        </tr>

        <tr>
          <th scope="row">{{'pkc.h7'|translate}}</th>
          <td class="text-center">{{ input?.total_mobile ? input?.total_mobile : 0 }}</td>
          <td>
            <div class="d-flex align-items-center">
              <span class="fix-size-custom"> {{ input?.percent_mobile ? input?.percent_mobile  + '%': '0 %'}} </span>
              <!-- <div>
                <div class="progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    aria-valuenow="input?.percent_mobile"
                    aria-valuemin="0"
                    aria-valuemax="100"
                    [style.background-color]="input?.mobileColor"
                    [style.width]="input?.percent_mobile + '%' "
                  ></div>
                </div>
              </div> -->
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
