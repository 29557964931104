<div class="card">
  <div class="card-header border-0">
    <div class="row">
      <div class="col-6">
        <h3 class="mb-0">{{'arc.title_' + operationType | translate}}</h3>
      </div>
      <div class="col-6 text-right">
        <a class="btn btn-sm btn-success btn-round btn-icon table-action" tooltip="Add entries" placement="top" data-target="#modal-form" data-toggle="modal" (click)="openFormModal()">
          <span class="btn-inner--icon">
            <i class="fa fa-plus text-white" aria-hidden="true"></i>
          </span>
          <span class="btn-inner--text text-white">{{'arc.add' | translate}}</span>
        </a>
      </div>
    </div>
  </div>
  
  <div class="table-responsive">
    <table class="table align-items-center table-flush table-striped">
      <thead class="thead-light">
        <tr>
          <th></th>
          <th>{{'arc.displayName' | translate}}</th>
          <th>{{'arc.variableName' | translate}}</th>
          <th>{{'arc.type' | translate}}</th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody *ngIf="appRequests" cdkDropList (cdkDropListDropped)="onDrop($event, data)">
        <tr *ngFor="let input of data" cdkDrag cdkDragLockAxis="y">
          <th>
           <div class="drag-handle">
              <ng-container>
                <div class="sidenav-toggler-inner pointer bg-primary">
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                  <i class="sidenav-toggler-line"></i>
                </div>
              </ng-container>
            </div> 
          </th>
          <td class="table-user"><b>{{ input.displayName }}</b></td>
          <td class="table-user"><b>{{ input.name }}</b></td>
          <td><b>{{ input.type }}</b></td>
          <td class="table-user">
          </td>
          <td class="table-actions">
            <a class="table-action" tooltip="Update" placement="top" data-target="#modal-form" data-toggle="modal" (click)="openUpdateFormModal(input.id)">
              <i class="fas fa-edit"></i>
            </a>
            <a class="table-action" tooltip="Delete" placement="top" data-target="#modal-form" data-toggle="modal" (click)="deleteAppRequest(input.id)">
              <i class="fa fa-trash bg-icon-danger"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<!-- MODAL -->
<ng-template #modalForm>
  <div class="modal-body p-0">
    <div class="card bg-light border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-5">
        <div class="text-center mb-4">
          <p>{{ updateMode ? ('arc.updateInputOutput' | translate) : ('arc.addInputOutput' | translate) }}</p>
        </div>
        <form [formGroup]="appRequestFg" (ngSubmit)="submit()">

          <!-- DISPLAYNAME -->
          <div class="form-group">
            <label class="form-control-label" for="displayName">{{'arc.displayName' | translate}}</label>
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text"></span>
              </div>
              <input id="displayName" class="form-control" placeholder="{{'arc.visibleName' | translate}}" formControlName="displayName" type="text" name="displayName"/>
            </div>
          </div>

            <!-- VARIABLE NAME -->
            <div class="form-group">
              <label class="form-control-label" for="name">{{'arc.variableName' | translate}}</label>
              <div class="input-group input-group-alternative">
                <div class="input-group-prepend">
                  <span class="input-group-text"></span>
                </div>
                <input id="name" class="form-control" placeholder="{{'arc.variableNameText' | translate}}" formControlName="name" type="text" name="name"/>
              </div>
            </div>

          <!-- REQUEST TYPE -->
          <div class="form-group">
            <label class="form-control-label" for="type">{{'arc.type' | translate}}</label>
            <div class="input-group input-group-alternative">
              <div class="input-group-prepend">
                <span class="input-group-text"></span>
              </div>
              <select class="form-control" formControlName="type" id="type">
                <option [ngValue]="requestTypes[1].value" disabled selected>{{'tf.' + requestTypes[1].label | translate}}</option>
                <option *ngFor="let requestType of requestTypes" [ngValue]="requestType.value">{{'tf.' + requestType.label | translate}}</option>
              </select>
            </div>
          </div>
          
          <div class="form-group">
            <!-- REQUIRED CHECKBOX -->
            <div *ngIf="operationType == 'INPUT'" class="col">
              <div class="form-group d-flex align-items-center">
                <input id="required" type="checkbox" formControlName="required" (change)="onRequiredChange($event)" class="form-check-input mr-2" />
                <label class="form-control-label mb-0" for="required">{{'arc.required' | translate}}</label>
              </div>
            </div>
            <!-- CATEGORICAL CHECKBOX -->
            <div *ngIf="operationType == 'INPUT' && (['TEXT','NUMBER'].includes(appRequestFg.get('type').value))" class="col">
              <div class="form-group d-flex align-items-center">
                <input id="categorical" type="checkbox" formControlName="categorical" (change)="onCategoricalChange($event)" class="form-check-input mr-2" />
                <label class="form-control-label mb-0" for="categorical">{{'arc.categorical' | translate}}</label>
              </div>
            </div>
          </div>

          <!-- PLACEHOLDER -->
          <div class="form-row">
            <div *ngIf="(appRequestFg.get('categorical').value === false) && (['TEXT','NUMBER','TEXTAREA'].includes(appRequestFg.get('type').value))" class="col">
              <div class="form-group">
                <label class="form-control-label" for="placeholder">{{'arc.placeholder' | translate}}</label>
                <div class="input-group input-group-alternative">
                  <div class="input-group-prepend">
                    <span class="input-group-text"></span>
                  </div>
                  <input id="placeholder" class="form-control" placeholder="{{'arc.visibleName' | translate}}" formControlName="placeholder" type="text" name="placeholder"/>
                </div>
              </div>
            </div>
          </div>
  <!-- TAGS -->
  <div class="form-row">
    <div *ngIf="(appRequestFg.get('categorical').value === true)" class="col">
      <label class="form-control-label" for="placeholder">{{'arc.addChoices' | translate}}</label>
      <div class="input-group d-flex align-items-center">
        <div class="form-control d-flex align-items-center flex-wrap position-relative" id="tags-container" style="height: auto; padding: 0.5rem;">
          <span *ngFor="let tag of placeholderTags" class="badge bg-primary text-white me-1 mb-1 d-flex align-items-center">
            {{ tag }}
            <i class="fa fa-times ml-1 ms-1" (click)="removeTag(tag)" style="cursor: pointer;"></i>
          </span>
          <input #tagInput 
          id="placeholderTags" 
          class="tag-input border-0 flex-grow-1 ml-1" 
          type="text" 
          placeholder="" 
          (keydown)="onKeydown($event, tagInput)" 
          style="outline: none; box-shadow: none; padding: 0; margin: 0; width: 10px;" />
   
        </div>
      </div>
    </div>
  </div>

          <!-- SUBMIT BUTTON -->
          <br>
          <div class="text-center">
            <button type="submit" class="btn btn-primary my-4">{{'arc.submit' | translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>
