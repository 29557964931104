import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { S3ServiceService } from 'src/app/services/s3-service.service';
import { TemplateT3Service } from 'src/app/services/template.t3.service';
import { environment } from 'src/environments/environment';
import JSZip from 'jszip';
import { Application } from 'src/app/models/application.model';
import { RenameRequest, UserFileRequest } from 'src/app/models/user-file.model';
import { PlatformService } from 'src/app/services/plateform.service';

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;
  @Input() x = 0;
  @Input() y = 0;
  @Input() applicationId: string;
  @Input() basePath: string;
  @Input() bucketName: string;
  @Input() fileOrFolder: any;
  @Input() storageSize: number;
  @Input() refresh: () => void;
  @Input() fireUsageAlert: () => void;
  @Input() disableContextMenu: () => void;
  @Input() updateActiveCol: (id: string) => void;
  @Input() openFormModal: () => void;
  @Input() application: Application;

  isAFolder: boolean;
  canBeRenamed: boolean;
  hoveredItem:string; //todo jk ang17
  childrenPaths: Object[] = [];
  constructor(
    private storageService: S3ServiceService,
    private templateT3Service: TemplateT3Service,
    private platformeService: PlatformService) { }

  ngOnInit() {
    this.isAFolder = this.fileOrFolder?.children.length != 0;
    let fileOrFolderSize = this.convertBytesIntoGb(this.calculateTotalSize(this.fileOrFolder));
    this.canBeRenamed = fileOrFolderSize < environment.PI_TRANSFER_RENAME_GB_LIMIT; 
  }

  ngOnChanges() {
    this.isAFolder = this.fileOrFolder?.children.length != 0;
  }

  private getAllChildrensPath(obj) {
    for (var k in obj) {
      if (typeof obj[k] == "object" && obj[k]['children'].length !== 0)
        this.getAllChildrensPath(obj[k]['children']);
      else {
        this.childrenPaths.push({ Key: this.basePath + obj[k]['path'] });
      }
    }
  }

  public async handleDeleteObject(fileOrFolder) {
    if (this.isAFolder) {
      this.getAllChildrensPath(fileOrFolder.children);
      await this.handleDeleteUserFileByFolder();
      await this.storageService.deleteObjects(this.childrenPaths, this.bucketName);
    } else {
      await this.handleDeleteUserFileByFilePath();
      await this.storageService.deleteObject(this.basePath + fileOrFolder.path, this.bucketName);
    }

    this.disableContextMenu();
    this.updateActiveCol(fileOrFolder.col);
    this.refresh();
  }

  private async handleDeleteUserFileByFilePath() {
    let userFileRequest: UserFileRequest = {
      filePath: this.fileOrFolder.path
    };

    // deletealluserfilesbyfilepath
    this.templateT3Service
      .deleteUserFiles(userFileRequest,this.applicationId, false, null)
      .subscribe((response) => { });
  }

  private async handleDeleteUserFileByFolder() {
      const userFileRequest: UserFileRequest = {
        filePath: this.fileOrFolder.path
      }
  
      this.templateT3Service.deleteUserFiles(userFileRequest, this.applicationId, true, null)
      .subscribe(() => {});
  }

  public async handleDownloadObject(fileOrFolder) {
    if (this.isAFolder) {
      await this.downloadFolder(fileOrFolder);
      this.disableContextMenu();
    } else {
      await this.downloadFile(fileOrFolder);
      this.disableContextMenu();
    }
  }

  private async downloadFile(fileOrFolder) {
    let url = null
    if (!this.isAFolder) {
      url = await this.storageService.getObject(this.basePath + fileOrFolder.path, 3600, this.bucketName);
      const response = await fetch(url);
      const blob = await response.blob();
      this.downloadBlob(blob, fileOrFolder.key_decoded);
    }
  }

  private async downloadFolder(folder) {
    const jszip = new JSZip();
    await this.createZipFile(folder, jszip);
    const zipBlob = await jszip.generateAsync({ type: 'blob' });
    this.downloadBlob(zipBlob, folder.key_decoded + '.zip');
  }

  private async createZipFile(folder, jszip) {
    let url = null
    for (const fileOrFolder of folder.children) {
      if (!this.isAFolder) {
        url = await this.storageService.getObject(this.basePath + fileOrFolder.path, 3600, this.bucketName);
        const response = await fetch(url);
        const blob = await response.blob();
        jszip.file(fileOrFolder.key_decoded, blob);
      } else {
        const subfolder = jszip.folder(fileOrFolder.key_decoded);
        await this.createZipFile(fileOrFolder, subfolder);
      }
    }
  }

  private downloadBlob(blob, fileName) {
    if(this.platformeService.isPlatformBrowser()){
      const objectUrl = URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = objectUrl;
      anchor.download = fileName;
      anchor.click();
      URL.revokeObjectURL(objectUrl);
    }
  }

  private async processChildrenRecursively(children, newPath, fileOrFolder) {
    for (const child of children) {
      const childPath = newPath + "/" + child.key;
      if (child.children.length > 0) {
        await this.processChildrenRecursively(child.children, childPath, fileOrFolder);
      }
      // folder
      this.renameUserFiles(child.path, childPath);
    }
  }

  public async handleRenameObject(fileOrFolder) {
    if(this.platformeService.isPlatformBrowser()){
      const parent = fileOrFolder
      // FOLDER
      if (this.isAFolder) {
        let newFolderName = window.prompt('Enter new folder name:', '');
        if (newFolderName != null && newFolderName != '' && newFolderName != fileOrFolder.key_decoded) {
          const oldPath = fileOrFolder.path;
          const directoryPath = oldPath.substring(0, oldPath.lastIndexOf('/') + 1);
          const newPath = directoryPath + newFolderName;
          fileOrFolder.path = newPath;
          const copiedPaths = new Set();
          await this.updateChildrenPaths(fileOrFolder.children, newPath, copiedPaths);
          //this.processChildrenRecursively(fileOrFolder.children, newPath, fileOrFolder.path);
          this.renameUserFiles(oldPath, newPath);// folder
          this.handleDeleteObject(fileOrFolder);
          this.disableContextMenu();
          this.updateActiveCol(fileOrFolder.col);
          this.refresh();
        } else {
          window.alert('Not a correct folder name');
          return;
        }
        // FILE
      } else {
        const newFileName = window.prompt('➡️ Enter the new filename \n \n💡 Only files & folders < 10Mo can be renamed.', '');
        if (newFileName != null && newFileName != '' && newFileName != fileOrFolder.key_decoded) {
          const keySource = this.basePath +  fileOrFolder.path;
          const oldPath = fileOrFolder.path;
          const directoryPath = oldPath.substring(0, oldPath.lastIndexOf('/') + 1);
          const newPath =  directoryPath + newFileName;
          const keyTarget = this.basePath + newPath;

          this.renameUserFiles(oldPath, newPath);// file
          await this.storageService.mooveObject(keySource, keyTarget, this.bucketName);
        } else {
          window.alert('Not a correct file name');
          return;
        }
        this.handleDeleteObject(fileOrFolder);
        this.disableContextMenu();
        this.updateActiveCol(fileOrFolder.col);
        this.refresh();
      }
    }
  }

  public async createEmptyDirectory(fileOrFolder): Promise<void> {
    if(this.platformeService.isPlatformBrowser()){
      const folderName = window.prompt('Entrez le nom du nouveau dossier :', '');
      if (folderName && folderName.trim() !== '') {
          // Créer le chemin complet du nouveau dossier
          if (fileOrFolder){
          const newFolderPath = this.basePath + fileOrFolder.path+'/'+ folderName + '/';
          await this.storageService.createEmptyDirectory(newFolderPath, this.bucketName);
          this.refresh();
        }else{
          const newFolderPath = this.basePath + '/'+ folderName + '/';
          await this.storageService.createEmptyDirectory(newFolderPath, this.bucketName);
        }
          this.refresh();
      } 
    }
}
  private async updateChildrenPaths(children, newpath, copiedPaths) {
    let childs = "/";
    for (const child of children) {
      // Si le child a des enfants, récursivement traiter les enfants
      if (child.children.length > 0) {
        childs = childs + child.key;
        await this.processChildrenRecursive(child.children, newpath, childs);
      }
      if (child.children.length === 0) {
        const keySource = this.basePath + child.path;
        const keyTarget = this.basePath + newpath + "/" + child.key;
        await this.storageService.mooveObject(
          keySource,
          keyTarget,
          this.bucketName
        );
        copiedPaths.add(child.path);
      }
    }
  }
  private async processChildrenRecursive(children, newpath, childs) {
    for (const child of children) {
      const childPath = childs + "/" + child.key;

      // Concaténer le chemin du child au chemin actuel
      if (child.children.length > 0) {
        // Si le child a des enfants, récursivement traiter les enfants
        await this.processChildrenRecursive(child.children, newpath, childPath);
      }

      const keySource = this.basePath + child.path;
      const keyTarget = this.basePath + newpath + childPath;
      if (child.children.length === 0) {
        await this.storageService.mooveObject(
          keySource,
          keyTarget,
          this.bucketName
        );
      }
    }
  }

  public openFileInput(): void {
    if(this.platformeService.isPlatformBrowser()){
      this.fileInput.nativeElement.click();
    }
  }

  public handleFileInput(event: any) {
    const files: FileList = event.target.files;
    if (files.length > 0) {
      const selectedFile: File = files[0];
      let usageValid = (this.storageSize + this.convertBytesIntoGb(selectedFile.size)) < environment.PI_TRANSFER_GB_LIMIT;
      if(!usageValid ) {this.fireUsageAlert();return;}
      this.uploadFile(selectedFile);
    }
  }
  
  public async uploadFile(file: File) {
    const baseSubPath =  this.fileOrFolder.path + '/';
    await this.storageService.loadFiles([file]);
    await this.storageService.uploadSelectedFiles(this.bucketName, this.basePath, baseSubPath);
    
    this.disableContextMenu();
    this.updateActiveCol(this.fileOrFolder.col);
    this.refresh();
  }
  
  private renameUserFiles(filePath: string, newFilePath: string) {
    const renameRequest: RenameRequest = {
      applicationId: this.applicationId,
      filePath: filePath,
      isAFolder: false,
      newName: newFilePath
    };
    this.templateT3Service.renameUserFiles(renameRequest).subscribe((response) => {}
    );
  }

  private convertBytesIntoGb(bytesValue:number){
    return bytesValue / (1024 * 1024 * 1024);
  }

  private calculateTotalSize(folder: any): number {
    let totalSize = folder.size || 0;

    for (const child of folder.children) {
        totalSize += this.calculateTotalSize(child);
    }

    return totalSize;
}
}
