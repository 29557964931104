import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import Chart from "chart.js";
import { AnalyticsService } from 'src/app/services/analytics.service';
import { Observable, Subscription } from 'rxjs';
import { Application } from 'src/app/models/application.model';
import { Store } from '@ngrx/store';
import { selectCurrentApplication, selectCurrentApplicationIsConfigComplete } from 'src/app/store/repository/repository.selector';
import { selectIsUserSubscribedToPirogPro } from 'src/app/store/auth/auth.selectors';
import { Timeseries, ChartTimeseries } from "src/app/models/analytics.model";
import { SeoService } from "src/app/services/seo.service";
import { PlatformService } from "src/app/services/plateform.service";

@Component({
  selector: "app-pianalytics",
  templateUrl: "pianalytics.component.html",
  styleUrls: ['pianalytics.component.scss']
})

export class PiAnalyticsComponent implements OnInit, OnDestroy {

  application$: Observable<Application>;
  isConfigComplete$: Observable<boolean>;
  isSubscribedToPirogPro$: Observable<boolean>;

  subscriptions: Subscription = new Subscription();
  application: Application;
  usersDataDesktop: ChartTimeseries[];
  usersDataMobile: ChartTimeseries[];
  visitorsDataDesktop: ChartTimeseries[];
  visitorsDataMobile: ChartTimeseries[];
  public usersData: any;
  public visitorsData: any;
  public usersKpi: any;
  public visitorsKpi: any;
  public desktopColor ='cornflowerblue';
  public mobileColor = 'cadetblue';
 
  constructor(
    private store: Store, 
    private analyticsService: AnalyticsService,
    private seoService: SeoService,
    private platformeService: PlatformService) {}
 

  ngOnInit() {
    this.seoService.setSeoTags({titleKey:"seo.pianalytics.title", descriptionKey:"seo.pianalytics.description", noindex: true});

    this.initData();
    this.application$.subscribe(
      response => {
        this.application = response;
        this.getData(); 
      })
    }

  private initData():void {
    this.application$ = this.store.select(selectCurrentApplication);
    this.isConfigComplete$ = this.store.select(selectCurrentApplicationIsConfigComplete);
    this.isSubscribedToPirogPro$ = this.store.select(selectIsUserSubscribedToPirogPro);
  }

  private filterChartData(data, profile, device):ChartTimeseries[] {
    let out = data.filter((obj) => (obj.profile === profile) && (obj.device === device)).map(userData => { return {x:userData.date, y:userData.value}});
    return out
  }

  private getData(): void {
    this.analyticsService
      .getTimeseriesByApplication(this.application.id)
      .subscribe((response) => {
        if(response.length >= 0) {
          this.visitorsDataMobile = this.filterChartData(response, "visitor", "mobile");
          this.visitorsDataDesktop = this.filterChartData(response, "visitor", "desktop");
          this.usersDataMobile = this.filterChartData(response, "user", "mobile");
          this.usersDataDesktop = this.filterChartData(response, "user", "desktop");
          
          this.usersData = {
            y_desktop: this.usersDataDesktop,
            y_mobile: this.usersDataMobile,
            id: 'users_ts',
            title: 'users'
          };
          this.visitorsData = {
            y_desktop: this.visitorsDataDesktop,
            y_mobile: this.visitorsDataMobile,
            id: 'visitors_ts',
            title: 'visitors'
          };
          
          this.generateTimeseriesChart(this.usersData);
          this.generateTimeseriesChart(this.visitorsData);
          this.getKpi();
        }
      });
  }

  private getKpi() : void {
    this.analyticsService
        .getKpiByApplication(this.application.id)
        .subscribe((response) => {
          if(response) {

            let totalUsers = response[0]?.nbUsersDesktop + response[0]?.nbUsersMobile;
            let deltaUsers28days = this.calculateKpiDelta28days(this.usersData);
            this.usersKpi = {
              title: "Users",
              total: totalUsers,
              total_delta: deltaUsers28days,
              total_desktop: response[0]?.nbUsersDesktop,
              total_mobile: response[0]?.nbUsersMobile,
              percent_desktop:Number((response[0]?.nbUsersDesktop*100/totalUsers).toFixed(1)),
              percent_mobile:Number((response[0]?.nbUsersMobile*100/totalUsers).toFixed(1)),
              desktopColor: this.desktopColor,
              mobileColor: this.mobileColor
            };

            let totalVisitors= response[0]?.nbVisitorsDesktop + response[0]?.nbVisitorsMobile
            let deltaVisitors28days = this.calculateKpiDelta28days(this.visitorsData);
            this.visitorsKpi = {
              title: "Visitors",
              total: totalVisitors,
              total_delta: deltaVisitors28days,
              total_desktop: response[0]?.nbVisitorsDesktop,
              total_mobile: response[0]?.nbVisitorsMobile,
              percent_desktop:Number((response[0]?.nbVisitorsDesktop*100/totalVisitors).toFixed(1)),
              percent_mobile:Number((response[0]?.nbVisitorsMobile*100/totalVisitors).toFixed(1)),
              desktopColor: this.desktopColor,
              mobileColor: this.mobileColor
            };
          }
        });
  }

  private calculateKpiFirstAndLastBasedOnTimeseries(y_device){
    // utils for calculating delta 28days KPI
    let first = 0
    let last = 0
    let size = y_device.length - 1;
    if(y_device.length ==1){
      first = y_device[0]['y'] 
      last = y_device[0]['y'] 
    }
    if(y_device.length < 28){
      first = y_device[0]['y'] 
      last = y_device[size]['y'] 
    } else {
      first = y_device[size-28]['y'] 
      last = y_device[size]['y'] 
    }
    return [first, last]
  }
  private calculateKpiDelta28days(data){
    // Calculation of delta 28days KPI
    let mobile = [0,0];
    let desktop = [0,0];

    if(data.y_mobile.length>0){
      mobile = this.calculateKpiFirstAndLastBasedOnTimeseries(data.y_mobile);
    } 
    if(data.y_desktop.length>0){
      desktop = this.calculateKpiFirstAndLastBasedOnTimeseries(data.y_desktop);
    } 

    var totalFirst = desktop[0] + mobile[0];
    let totalLast = desktop[1] + mobile[1];
    let total_delta = Number((100*(totalLast-totalFirst)/totalFirst).toFixed(1));
    total_delta = isNaN(total_delta) ? undefined : total_delta;
    return total_delta
  }

  private generateTimeseriesChart(data): void {
    if(this.platformeService.isPlatformBrowser()){
      var canva = document.getElementById(data.id);
      var dataFirst = {
        label: "Desktop",
        borderWidth: 3,
        //lineTension: 0, staight line
        fill: false,
        borderColor: this.desktopColor,//'#D81E5B',
        backgroundColor: "#fff",
        data: data.y_desktop
      };
    
      var dataSecond = {
        label: "Mobile",
        borderWidth: 3,
        //lineTension: 0, staight line
        fill: false,
        borderColor: this.mobileColor, //'#59C9A5',
        backgroundColor: "#fff",
        data: data.y_mobile
      };
    
      var speedData = {
        datasets: 
        [
          dataFirst, 
          dataSecond
        ]
      };
      var chartOptions = {
        scales: {
          xAxes: [{
            type: "time",
            time: {
                unit: 'day',
                parser: 'YYYY-MM-DD HH:mm:ss',
                displayFormats: {
                    day: 'MMM DD',
                },
            },
            ticks: {
                fontStyle: "normal",
                stepSize:1,
                source: 'data'
            },
              scaleLabel: {
                display: true,
                labelString: 'Date'
            }
          }],
          yAxes: [{
            scaleLabel: {
                display:     true,
                labelString: data.title
              },
            ticks: {
              min: 0, // it is for ignoring negative step.
              beginAtZero: true,
              callback: function(value, index, values) {
                  if (Math.floor(value) === value) {
                      return value;
                  }
              }
            }
          }]
        },
        legend: {
          responsive: true,
          maintainAspectRatio: false,
          display: true,
          position: 'top',
          labels: {
            boxWidth: 80,
            fontColor: 'black',
            usePointStyle: true,
          }
        },
        tooltips: {
          enabled: true,
          boxWidth:5,
          callbacks: {
              title: function(tooltipItem, data) {
                  var title = tooltipItem[0].xLabel.substring(0,10);
                  return title;
              },
              label: function(tooltipItem, data) {
                  
                  return ' value: ' + tooltipItem['value'];
              }
          }
      }
    };
    
    var lineChart = new Chart(canva, {
      type: 'line',
      data: speedData,
      options: chartOptions
    });
    }
  }

  ngOnDestroy() {}
}