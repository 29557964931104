import { Component, Input, OnInit } from "@angular/core";
import { NgxFileDropEntry } from "ngx-file-drop";

@Component({
  selector: 'app-filesystem-folder',
  templateUrl: 'filesystem-folder.component.html',
  styleUrls: ['./filesystem-folder.component.scss']
})
export class FilesystemFolderComponent implements OnInit {
  @Input() fileOrFolder: any;
  @Input() dropAndUpload: (filesNgx: NgxFileDropEntry[]) => void;
  @Input() fileOver: (event, destination) => void;
  @Input() isActiv: boolean = false;
  isAFolder: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isAFolder = this.fileOrFolder.children.length != 0;
  }

}

