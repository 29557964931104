
<!-- <app-application-navbar></app-application-navbar>
 -->
<!-- Pirog Pro -->
<div *ngIf="(isSubscribedToPirogPro$ | async); else freemium ">
  <div class="container-fluid mt-5">

    <!-- Visitors -->
    <div class="card-deck flex-column flex-xl-row">
      <div class="col-12 col-xl-4 px-0">
        <app-pianalytics-kpi *ngIf="visitorsKpi" [input]="visitorsKpi"></app-pianalytics-kpi>
      </div>
      <div class="col-12 col-xl-8 px-0">
        <div class="card">
          <div class="card-header bg-transparent text-primary">
            <h2 class="h3 mb-0">{{'pc.p15'|translate}}</h2>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="visitors_ts" class="chart-canvas" width="1000" height="350"> </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Users -->
    <div class="card-deck flex-column flex-xl-row">
      <div class="col-12 col-xl-4 px-0">
        <app-pianalytics-kpi *ngIf="usersKpi" [input]="usersKpi"></app-pianalytics-kpi>
      </div>
      <div class="col-12 col-xl-8 px-0">
        <div class="card">
          <div class="card-header bg-transparent">
            <h2 class="h3 mb-0">{{'pc.p14'|translate}}</h2>
          </div>
          <div class="card-body">
            <div class="chart">
              <canvas id="users_ts" class="chart-canvas" width="1000" height="350"> </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>

<!-- Pirog Freemium -->
<ng-template #freemium>
  <div class="container-fluid">
    <div class="card-deck flex-column flex-xl-row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h1>{{'pirog_pro.pianalytics.title' | translate}} <app-micro-app></app-micro-app> ?</h1>
            <p class="lead">{{'pirog_pro.pianalytics.description' | translate}}</p>
            <ul>
                <li class="h4 font-weight-light">
                    &nbsp; {{'pirog_pro.pianalytics.visitors' | translate}}
                </li>
                <li class="h4 font-weight-light">
                    &nbsp; {{'pirog_pro.pianalytics.users' | translate}}
                </li>
            </ul>
          </div>
          <div class="text-center">
            <pirog-pro-button [gold]="true" [buttonText]="'pirog_pro.button.free' | translate" [currentStepRecap]="'terms'" [iconMode]="false"></pirog-pro-button>
            <pirog-pro-button [buttonText]="'pirog_pro.button.plus' | translate" [currentStepRecap]="'description'" [iconMode]="false"></pirog-pro-button>
          </div>
          <br><br>
        </div>
      </div>
    </div>

  </div>
</ng-template>
