import { Component, OnInit, OnDestroy, Input } from "@angular/core";

import Chart from "chart.js";
import { PlatformService } from "src/app/services/plateform.service";

@Component({
  selector: "app-bar-stacked",
  templateUrl: "bar-stacked.component.html"
})

export class BarStackedComponent implements OnDestroy {
  
  @Input() title: any;
  @Input() subTitle: any;
  @Input() labels: any;
  @Input() datasets: any;
  constructor(private platformeService: PlatformService) {}

  ngAfterViewInit() {
    if(this.platformeService.isPlatformBrowser()){
      var chartBarStacked = <HTMLCanvasElement> document.getElementById('bar-stacked');
      const chartBarStackedData = {
        options: {
          tooltips: {
            mode: "index",
            intersect: false
          },
          responsive: true,
          scales: {
            xAxes: [{stacked: true}],
            yAxes: [{stacked: true}]
          }
        },
        data: {
          labels: this.labels,
          datasets: this.datasets
        }
      };
      // Init chart
      const barStackedChart = new Chart(chartBarStacked, {
        type: "bar",
        data: chartBarStackedData.data,
        options: chartBarStackedData.options
      });
    }
  }

  ngOnDestroy() {
  }
}
