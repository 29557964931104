import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { UserFileResponse } from "src/app/models/user-file.model";

export function CheckEmailAlreadyExist(
privateUserFiles: UserFileResponse[]
): ValidatorFn {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const email = formGroup.get('email');

    if(privateUserFiles == null) {
        email.setErrors(null);
        return null;
    }
    let emails =  privateUserFiles.map(obj => obj.email);
  
    // if (emails.includes(email.value)) {
    //     // console.log('BUG !!')
    //     email.setErrors({ passwordMismatch: true });
    // } else {
    //     email.setErrors(null);
    // }
    return null;
  };
}