import { Component, OnInit } from "@angular/core";
import { Application } from "src/app/models/application.model";
import { ApplicationService } from "src/app/services/application.service";
import { PlatformService } from "src/app/services/plateform.service";

@Component({
  selector: "app-applications-list-card",
  templateUrl: "applications-list-card.component.html",
  styleUrls: ['applications-list-card.component.scss']
})

export class ApplicationsListCardComponent implements OnInit {
    applications: Application[];

  constructor(private applicationService: ApplicationService, private platformService: PlatformService) {}

  ngOnInit() {
    this.applicationService.getApplications().subscribe(response => {
      this.applications = response;
    });
  }
}
