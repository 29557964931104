<div class="container-fluid mt--12">
  <div class="row justify-content-center">

    <div class="col-xl-12 my-4" >
      <div class="display-4 text-center my-4"> Publication Requests </div>
      <div class="row">
        <div class="col">
          <div class="card bg-default shadow">
            <div class="table-responsive">
              <table class="table align-items-center  table-flush">
                <thead class="">
                  <tr>
                    <th class="" scope="col">Application</th>
                    <th class="" scope="col">Type</th>
                    <th class="" scope="col">Headline</th>
                    <th class="" scope="col">Description</th>
                    <th class="" scope="col">Image</th>
                    <th class="" scope="col"></th>
                  </tr>
                </thead>

                <tbody class="list">
                  <tr *ngFor="let application of publicationRequestedApps">
                    <th scope="row">
                      <a href="https://{{application.domainUrl}}" target="_blank">
                        <div class="media align-items-center">
                          <div class="media-body">
                            <span class="name mb-0 text-sm">
                              {{ application.name }}
                            </span>
                          </div>
                        </div>
                      </a>

                    </th>

                    <td class="">{{ application.templateType }}</td>
                    <td class="">{{ application.publicHeadline }}</td>
                    <td class="">{{ application.publicDescription }}</td>
                    <td class=""><a href="{{application.publicImage}}" target="_blank"><i class="ni ni-image"></i></a></td>

                    <td>
                      <button class="btn btn-sm btn-success" type="button" (click)="acceptReview(application.id)">
                        Accept
                      </button>

                      <div class="input-group mt-1">
                        <div class="input-group-prepend">
                          <button class="btn btn-sm btn-danger" type="button" (click)="refuseReview(application.id)">
                            Refuse
                          </button>
                        </div>
                         <input type="text" [(ngModel)]="refusalReason" class="form-control form-control-sm" placeholder="" aria-label="" aria-describedby="basic-addon1">
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ACCOUNTS -->
    <div class="col-xl-12 my-4" >
      <div class="display-4 text-center my-4"> Accounts </div>
      <div class="row">
        <div class="col-md-4">
          <app-card
          [title]="'TOTAL'"
          [value]="dataAccount?.numberOfAccounts"
          [delta]="dataAccount?.variationOfAccountsSinceLastMonth + '%'"
          [class]="dataAccount?.variationOfAccountsSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-circle-08'">
        </app-card>
        </div>
        <div class="col-md-4">
          <app-card
          [title]="'PIROG_PRO'"
          [value]="dataAccount?.numberOfPirogProAccounts"
          [delta]="dataAccount?.variationOfPirogProAccountsSinceLastMonth + '%'"
          [class]="dataAccount?.variationOfPirogProAccountsSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-circle-08'">
        </app-card>
        </div>
        <div class="col-md-4">
          <app-card
          [title]="'FREEMIUM'"
          [value]="dataAccount?.numberOfFreemiumAccounts"
          [delta]="dataAccount?.variationOfFreemiumAccountsSinceLastMonth + '%'"
          [class]="dataAccount?.variationOfFreemiumAccountsSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-circle-08'">
        </app-card>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <app-bar-stacked *ngIf="dataAccountDetails" [title]="'Account profiles'" [subTitle]="'per workplace'" [labels]="dataAccountDetails?.labels" [datasets]="dataAccountDetails?.datasets"></app-bar-stacked>
        </div>
      </div>
    </div>

    <!-- Applications -->
    <div class="col-xl-12" >
      <div class="display-4 text-center my-4"> Applications </div>
      <div class="row justify-content-center">
        <div class="col-md-4">
          <app-card
          [title]="'TOTAL'"
          [value]="dataApplication?.numberOfApplications"
          [delta]="dataApplication?.variationOfApplicationsSinceLastMonth + '%'"
          [class]="dataApplication?.variationOfApplicationsSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-app'">
        </app-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <app-card
          [title]="'piFunction'"
          [value]="dataApplication?.numberOfPiFunction"
          [delta]="dataApplication?.variationOfPiFunctionSinceLastMonth + '%'"
          [class]="dataApplication?.variationOfPiFunctionSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-app'">
          </app-card>
        </div>
        <div class="col-md-3">
          <app-card
          [title]="'piProfile'"
          [value]="dataApplication?.numberOfPiProfile"
          [delta]="dataApplication?.variationOfPiProfileSinceLastMonth + '%'"
          [class]="dataApplication?.variationOfPiProfileSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-app'">
          </app-card>
        </div>
        <div class="col-md-3">
          <app-card
          [title]="'piTransfer'"
          [value]="dataApplication?.numberOfPiTransfer"
          [delta]="dataApplication?.variationOfPiTransferSinceLastMonth + '%'"
          [class]="dataApplication?.variationOfPiTransferSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-app'">
          </app-card>
        </div>
        <div class="col-md-3">
          <app-card
          [title]="'piChat'"
          [value]="dataApplication?.numberOfPiChat"
          [delta]="dataApplication?.variationOfPiChatSinceLastMonth + '%'"
          [class]="dataApplication?.variationOfPiChatSinceLastMonth < 0 ? 'bg-gradient-danger' : 'bg-gradient-success'"
          [icon]="'ni ni-app'">
          </app-card>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-12">
          <app-pie *ngIf="dataPieApplication" [data]="dataPieApplication" [labels]="labelsApplication" [colors]="colorsApplication" [title]="'applications'" [subTitle]="'Repartion entre les micro-apps'"></app-pie>
        </div>
      </div>
    </div>

    <!-- LIST OF USERS -->
    <div class="col-xl-12 my-4" >
      <div class="display-4 text-center my-4"> Users </div>
      <div class="row">
        <div class="col">
          <div class="card bg-default shadow">
            <div class="card-header bg-transparent border-0">
              <h3 class=" mb-0">Accounts list</h3>
            </div>

            <div class="table-responsive">
              <table class="table align-items-center table-flush">
                <thead class="">
                  <tr>
                    <th class="sort" data-sort="email" scope="col">email</th>
                    <th class="sort" data-sort="date" scope="col">date</th>
                    <th class="sort" data-sort="firstname" scope="col">name</th>
                    <th class="sort" data-sort="subscription" scope="col">subscription</th>
                    <th class="sort" data-sort="senabledtatus" scope="col">enabled</th>
                    <th class="sort" data-sort="phone" scope="col">piFunction</th>
                    <th class="sort" data-sort="phone" scope="col">piProfile</th>
                    <th class="sort" data-sort="phone" scope="col">piTransfer</th>
                    <th class="sort" data-sort="phone" scope="col">piChat</th>
                  </tr>
                </thead>

                <tbody *ngIf="dataAccount" class="list ">
                  <tr *ngFor="let account of dataAccount.accounts">
                    <th scope="row">
                      <div class="media align-items-center">
                        <div class="media-body">
                          <span class="name mb-0 text-sm ">
                            {{ account.email }}
                          </span>
                        </div>
                      </div>
                    </th>

                    <td class="date">{{ account.createdAt }}</td>
                    <td class="firstname">{{ account.firstname }} {{ account.lastname }}</td>
                    <td class="">{{ account.subscriptionType }}</td>
                    <td [ngClass]="account.enabled ? 'text-success' : 'text-warning'">{{ account.enabled ? '✓' : '❌'}}</td>
                    <td class="cells_domains">{{ account.t1_domains }}</td>
                    <td class="cells_domains">{{ account.t2_domains }}</td>
                    <td class="cells_domains">{{ account.t3_domains }}</td>
                    <td class="cells_domains">{{ account.t4_domains }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>





  </div>
</div>
