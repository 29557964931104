import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { BsModalRef, BsModalService, ModalDirective } from 'ngx-bootstrap/modal';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';
import { TemplateService } from 'src/app/services/template.service';

@Component({
  selector: 'app-page-builder',
  templateUrl: './page-builder.component.html',
  styleUrls: ['./page-builder.component.scss']
})
export class PageBuilderComponent implements OnInit {
  private _html: string = `<form _ngcontent-qip-c94="" novalidate="" class="ng-pristine ng-invalid ng-touched"><div _ngcontent-qip-c94="" class="form-group mb-3"><input _ngcontent-qip-c94="" placeholder="Testt" type="text" name="value" class="form-control ng-pristine ng-invalid ng-touched"><!----></div><div _ngcontent-qip-c94="" class="text-center"><button _ngcontent-qip-c94="" type="submit" class="btn btn-primary my-4"> Submit </button></div></form>`;
  @Input() application: Application;
  applicationUrl: string;
  templateRequests: ApplicationTemplateRequest[];

  // modal
  @ViewChild(ModalDirective, { static: false }) modal?: ModalDirective;
  defaultModal: BsModalRef;
  default = {
    keyboard: true,
    class: "modal-xxl"
  };

  messages?: string[];

  public get htmll(): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(this._html);
  }

  constructor(private store: Store, private _sanitizer: DomSanitizer, private templateService: TemplateService, private modalService: BsModalService) { }

  ngOnInit(): void {
    this.applicationUrl = this.application.domain.url;
    this.getData();
  }

  private getData(): void {
    this.templateService
      .getTemplateRequests(this.application.id)
      .subscribe((response) => {
        this.templateRequests = response;
      });
  }

  getTemplateRequests(subCategory: string): ApplicationTemplateRequest[] {
    return this.templateRequests.filter(a => a.templateRequest.subCategory === subCategory);
  }

  openDefaultModal(modalDefault: TemplateRef<any>) {
    this.messages = [];
    this.defaultModal = this.modalService.show(modalDefault, this.default);
  }
  closeDefaultModal() {
    this.defaultModal.hide();
  }

  public updateTemplateRequests = (): void => {
    this.getData();
  }
}
