<!--
<app-application-navbar></app-application-navbar>
-->
<div class="mt-4">
  <div class="row justify-content-center mx-0" *ngIf="application$ | async as application">
    <div id="pitransfer" class="col-11">
      <app-application-filesystem *ngIf="application.template?.templateType == 'piTransfer'" [application]="application"></app-application-filesystem>
    </div>
    <div id="application-requests" class="col-11">
      <app-application-requests *ngIf="application.template?.templateType == 'piFunction'" [application]="application"></app-application-requests>
    </div>
    <div id="template-requests" class="col-11">
      <app-application-template-requests [application]="application"></app-application-template-requests>
    </div>   
    <div id="page-builder" class="col-11">
      <app-page-builder [application]='application'></app-page-builder>
    </div>
  </div>
</div>
