<div *ngIf="fileOrFolder" class="list-group d-flex align-items-center justify-content-center contextmenu bg-pi-gray-dark text-white" [ngStyle]="{'left.px': x, 'top.px': y}">

    <!-- Share -->
    <div class="contextmenu-item d-flex align-items-center justify-content-center text-white" 
        (click)="openFormModal()"
        (mouseover)="hoveredItem = 'share'"
        (mouseout)="hoveredItem = null"
        [ngClass]="{'bg-pi-blue-fluo': hoveredItem === 'share'}">
        <span>{{'filesystem.share' | translate}}</span>
    </div>
  
    <!-- Create Folder -->
    <div *ngIf="isAFolder || fileOrFolder == undefined" 
        class="contextmenu-item d-flex align-items-center text-center justify-content-center text-white" 
        (click)="createEmptyDirectory(fileOrFolder)"
        (mouseover)="hoveredItem = 'createFolder'"
        (mouseout)="hoveredItem = null"
        [ngClass]="{'bg-pi-blue-fluo': hoveredItem === 'createFolder'}">
        <span>{{'filesystem.create_folder' | translate}}</span>
    </div>
  
    <!-- Create File -->
    <div *ngIf="fileOrFolder.children.length > 0" 
        class="contextmenu-item d-flex align-items-center justify-content-center text-white" 
        (click)="openFileInput()"
        (mouseover)="hoveredItem = 'create'"
        (mouseout)="hoveredItem = null"
        [ngClass]="{'bg-pi-blue-fluo': hoveredItem === 'create'}">
        <span>{{'filesystem.create' | translate}}</span>
    </div>
    <input type="file" #fileInput (change)="handleFileInput($event)" style="display: none;">
  
    <!-- Download -->
    <div class="contextmenu-item d-flex align-items-center justify-content-center text-white" 
        (click)="handleDownloadObject(fileOrFolder)"
        (mouseover)="hoveredItem = 'download'"
        (mouseout)="hoveredItem = null"
        [ngClass]="{'bg-pi-blue-fluo': hoveredItem === 'download'}">
        <span>{{'filesystem.download' | translate}}</span>
    </div>
  
    <!-- Rename -->
    <div *ngIf="canBeRenamed" 
        class="contextmenu-item d-flex align-items-center justify-content-center text-white" 
        (click)="handleRenameObject(fileOrFolder)"
        (mouseover)="hoveredItem = 'rename'"
        (mouseout)="hoveredItem = null"
        [ngClass]="{'bg-pi-blue-fluo': hoveredItem === 'rename'}">
        <span>{{'filesystem.rename' | translate}}</span>
    </div>
  
    <!-- Delete -->
    <div class="contextmenu-item d-flex align-items-center justify-content-center text-white" 
        (click)="handleDeleteObject(fileOrFolder)"
        (mouseover)="hoveredItem = 'delete'"
        (mouseout)="hoveredItem = null"
        [ngClass]="{'bg-pi-blue-fluo': hoveredItem === 'delete'}">
        <span>{{'filesystem.delete' | translate}}</span>
    </div>
  
  </div>
  