import { Component, OnInit, OnDestroy, Input } from "@angular/core";

@Component({
  selector: "app-card",
  templateUrl: "card.component.html"
})

export class CardComponent implements OnInit, OnDestroy {
  @Input() title: any;
  @Input() value: any;
  @Input() delta: any;
  @Input() class: any;
  @Input() icon: any;

  constructor() {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }
}
