import { Component, HostListener } from "@angular/core";
import { PlatformService } from "src/app/services/plateform.service";
import {Event, Router} from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"]
})
export class DashboardComponent {
  isMobileResolution: boolean;
  isApplication: boolean;

  constructor(public plateformService: PlatformService,
              private router: Router) {
    if(this.plateformService.isPlatformBrowser()){
      if (window.innerWidth < 1200) {
        this.isMobileResolution = true;
      } else {
        this.isMobileResolution = false;
      }
    }

    this.router.events.subscribe((event: Event) => {
      this.isApplication = this.router.url.includes("dashboard/applications/");
    });
  }

  @HostListener("window:resize", ["$event"])
  isMobile(event) {
    if(this.plateformService.isPlatformBrowser()){
      if (window.innerWidth < 1200) {
        this.isMobileResolution = true;
      } else {
        this.isMobileResolution = false;
      }
    }
  }
}
