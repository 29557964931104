import { Component, OnInit, OnDestroy, Input } from "@angular/core";

import Chart from "chart.js";
import { PlatformService } from "src/app/services/plateform.service";

@Component({
  selector: "app-pie",
  templateUrl: "pie.component.html"
})

export class PieComponent implements OnDestroy {
  @Input() data: any;
  @Input() labels: any;
  @Input() colors: any;
  @Input() title: any;
  @Input() subTitle: any;
  
  constructor(private platformeService: PlatformService){}

  ngAfterViewInit() {
    if(this.platformeService.isPlatformBrowser()){
      var chartPie = <HTMLCanvasElement> document.getElementById("chart-pie");
      console.log('data', this.data);
      console.log('labels', this.labels);
      console.log('colors', this.colors);
      console.log('title', this.title);
      console.log('subTitle', this.subTitle);

      const chartPieData = {
        data: {
          labels: this.labels,
          datasets: [
            {
              data: this.data,
              backgroundColor: this.colors,
              label: "Dataset 1"
            }
          ]
        },
        options: {
          responsive: true,
          legend: {
            position: "top"
          },
          animation: {
            animateScale: true,
            animateRotate: true
          }
        }
      };

      var pieChart = new Chart(chartPie, {
        type: "pie",
        data: chartPieData.data,
        options: chartPieData.options
      });
    }
  }

  ngOnDestroy() {
  }
}
