<div class="p-4" *ngIf="user">
  <div class="row">
    <div class="col-lg-6">
      <div class="col card w-100 h-75">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-auto">
              <app-profile-image avatarSize="xl" [showName]="false"></app-profile-image>
            </div>
            <div class="col ml-2">
              <h4 class="mb-0">{{ user.firstname }} {{ user.lastname }}</h4>
              <p class="text-sm text-muted mb-0"
                 *ngIf="user.role === 'ROLE_ADMIN'; else clientRole">{{ 'admin' | translate }}</p>
              <ng-template #clientRole>
                <p class="text-sm text-muted mb-0">{{ 'user' | translate }}</p>
              </ng-template>
              <span *ngIf="user.identityProvider === 'PIROG'; else googleSSO">
                
                <img src="assets/img/icons/common/icon_pirog_color.png" alt="Pirog Icon" style="width: 22px; height: 22px;">
              </span>
              <ng-template #googleSSO>
                <img src="assets/img/logo/logo_google.png" alt="Pirog Icon" style="width: 22px; height: 22px;">
              </ng-template>
              
              <span *ngIf="user.enabled" class="text-success"> ● </span>
              <span *ngIf="!user.enabled" class="text-danger"> ● </span>
              <small> {{ user.enabled ? ('pc.v' | translate) : ('pc.uv' | translate) }} </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div *ngIf="!user.subscribedToPirogPro" class="card h-75">
        <div class="card-body d-flex justify-content-between gap-3 align-items-center">
          <div>
            <h5 class="card-title text-uppercase text-muted mb-0">{{ 'account.subscription' | translate }}</h5>
            <span class="h1  mb-0 text-primary"> {{ 'account.freemium' | translate }} </span>
          </div>

          <pirog-pro-button [gold]="true" [iconMode]="false"></pirog-pro-button>
        </div>
      </div>
      <div *ngIf="user.subscribedToPirogPro" class="card h-75">
        <div class="card-body">
          <div class="d-flex">
            <div class="col">
              <h5 class="card-title text-uppercase text-muted mb-0">
                {{ 'account.subscription' | translate }}
              </h5>
              <span class="h1 font-weight-bold mb-0 text-primary"> {{ 'account.pirog_pro' | translate }} </span>
            </div>
            <div class="col-auto">
              <a class="btn btn-primary btn-sm" href="{{stripeUrl}}" target="_blank">{{
                  'account.billing' | translate
                }}</a>
            </div>
          </div>

          <p class="mt-3 mb-0 text-sm">
            <span class="text-success mr-1">
              <i class="fas fa-crown mr-1"> </i> {{ 'account.member' | translate }}
            </span>
            <span class="text-nowrap"> {{ 'account.since' | translate }} {{ user.createdAt }} </span>
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- User Information Form -->
  <div class="card">
    <div class="card-header">
      <h3 class="mb-0">{{ 'pc.h1' | translate }}</h3>
    </div>

    <div class="card-body">
      <form [formGroup]="userFormGroup" (ngSubmit)="updateUserInformation()">
        <h6 class="heading-small text-muted mb-4"> {{ 'pc.h2' | translate }}</h6>
        <div class="row">
          <div class="form-group col-lg-6">
            <label class="form-control-label" for="input-firstname">{{ 'pc.h3' | translate }}</label>
            <input class="form-control" id="input-firstname" type="text" disabled [value]="user.firstname"/>
          </div>

          <div class="form-group col-lg-6">
            <label class="form-control-label" for="input-lastname">{{ 'pc.p1' | translate }}</label>
            <input class="form-control" id="input-lastname" type="text" disabled [value]="user.lastname"/>
          </div>

          <div class="form-group col-lg-6">
            <label class="form-control-label" for="input-phone">{{ 'pc.p2'|translate }}</label>
            <input class="form-control" formControlName="phone" id="input-phone" type="text"/>
          </div>

          <div class="form-group col-lg-6">
            <label class="form-control-label">{{ 'navbar.language' | translate }}</label>
            <select class="form-control" data-toggle="select" formControlName="language">
              <option value="fr">{{ 'navbar.fr' | translate }}</option>
              <option value="en">{{ 'navbar.en' | translate }}</option>
            </select>
          </div>

          <div class="form-group col-lg-6">
            <label class="form-control-label" for="input-email">{{ 'pc.p3' | translate }}</label>
            <input class="form-control" id="input-email" disabled type="email" [value]="user.email"/>
          </div>
        </div>

        <div *ngIf="user.identityProvider !== 'GOOGLE'"
             class="custom-control custom-checkbox mb-3 mt-3">
          <input class="custom-control-input" id="customCheck1" type="checkbox"
                 formControlName="isUpdatingPassword"/>

          <label class="custom-control-label" for="customCheck1">
            <h6 class="heading-small text-muted mb-4"> {{ 'pc.p13' | translate }}</h6>
          </label>
        </div>

        <div class="row" *ngIf="userFormGroup.controls.isUpdatingPassword.value">
          <div class="form-group col-lg-6">
            <label class="form-control-label" for="input-old-password">{{ 'pc.p4' | translate }}</label>
            <div class="input-group">
              <input class="form-control" id="input-old-password" formControlName="oldPassword"
                     [type]="showOldPassword ? 'text' : 'password'" aria-label="Old Password"
                     autocomplete="off"/>
              <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" (click)="showOldPassword = !showOldPassword">
                  <i *ngIf="!showOldPassword" class="fa fa-eye"></i>
                  <i *ngIf="showOldPassword" class="fa fa-eye-slash"></i>
                </button>
              </div>
            </div>
          </div>

          <div class="form-group col-lg-6">
            <label class="form-control-label" for="input-new-password">{{ 'pc.p5' | translate }}</label>
            <div class="input-group">
              <input class="form-control" id="input-new-password" formControlName="newPassword"
                     [type]="showNewPassword ? 'text' : 'password'" aria-label="New Password"
                     autocomplete="off"/>
              <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" (click)="showNewPassword = !showNewPassword">
                  <i *ngIf="!showNewPassword" class="fa fa-eye"></i>
                  <i *ngIf="showNewPassword" class="fa fa-eye-slash"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary my-2 mt-4">
          {{ 'pc.p6' | translate }}
        </button>
      </form>
    </div>
  </div>

  <app-have-to-verify-account class="d-block mb-4"></app-have-to-verify-account>

  <div class="row">
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0"> {{ 'pc.p7' | translate }}</h3>
        </div>
        <div class="card-body p-0">
          <ul class="list-group list-group-flush">
            <li class="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
              <div class="checklist-item checklist-item-success">
                <div class="checklist-info">
                  <h5 class="checklist-title mb-0">{{ 'pc.p8' | translate }}</h5>
                  <small> {{ user.createdAt | date: 'dd/MM/yyyy' }}</small>
                </div>
              </div>
            </li>
            <li class="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
              <div class="checklist-item checklist-item-success">
                <div class="checklist-info">
                  <h5 class="checklist-title mb-0"> {{ 'pc.p9' | translate }}</h5>

                  <small> {{ user.updatedAt | date: 'dd/MM/yyyy' }} </small>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="card">
        <div class="card-header">
          <h3 class="mb-0">{{ 'pc.p10' | translate }}</h3>
        </div>
        <div class="card-body p-0">
          <ul *ngIf="user.subscribedToPirogPro" class="list-group list-group-flush">
            <li class="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
              <h5 class="checklist-title mb-0"> {{ 'account.unsubscribe' | translate }} </h5>
              <p class="text-sm"> {{ 'account.unsubscribe_description' | translate }} </p>
              <button type="submit" class="btn btn-primary btn-sm w-100 mt-1" (click)="unsubscribeFromPirogPro()">

                {{ 'account.unsubscribe_action' | translate }}
              </button>
            </li>
          </ul>
          <ul class="list-group list-group-flush">
            <li class="checklist-entry list-group-item flex-column align-items-start py-4 px-4">
              <h5 class="checklist-title mb-0"> {{ 'pc.p11' | translate }}</h5>
              <p class="text-sm"> {{ 'pc.p12' | translate }}</p>
              <button type="submit" class="btn btn-warning btn-sm w-100 mt-1" (click)="deleteAccount()">
                {{ 'pc.p11' | translate }}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
