<head>
    <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet'>
    <!-- local case -->
    <link href="https://dev.env.pirog.io/t2/assets/css/style.css" rel="stylesheet">
    <!-- dev & prd -->
    <link href="/t2/assets/css/style.css" rel="stylesheet">
</head>
<div id="header" style="position: unset !important;">
    <div class="d-flex flex-column">

        <div class="profile">
            <img (click)="openRequestsModal('account-image')" src="/assets/img/theme/user.png" alt="" class="img-fluid rounded-circle template-request-link">
            <h1 class="text-light">
                <a  (click)="openRequestsModal('personal-infos')" class="template-request-link nav-link scrollto w-100">
                    {{completName}}<br>
                    {{job}}
                </a>
            </h1>
            <a (click)="openRequestsModal('social-medias')" >
                <div class="social-links mt-3 text-center template-request-link">
                    <a class="twitter"><i class="bx bxl-twitter"></i></a>
                    <a class="facebook"><i class="bx bxl-facebook"></i></a>
                    <a class="instagram"><i class="bx bxl-instagram"></i></a>
                    <a class="linkedin"><i class="bx bxl-linkedin"></i></a>
                </div>
            </a>
        </div>

        <nav id="navbar" class="nav-menu navbar text-white">
            <ul class="w-100">
                <li class="w-100">
                    <a (click)="openRequestsModal('about')" class="template-request-link nav-link scrollto w-100"><i
                            class="bx bx-user"></i>
                        <span>{{"t2.admin.page_builder.about"|translate}}</span>
                    </a>

                </li>
                <li class="w-100">
                    <a (click)="openRequestsModal('experiences')" class="template-request-link nav-link scrollto w-100"><i
                            class="bx bx-book-content"></i>
                        <span>{{"t2.admin.page_builder.experience"|translate}}</span>
                    </a>
                </li>
                <li class="w-100">
                    <a (click)="openRequestsModal('skills')" class="template-request-link nav-link scrollto w-100"><i
                            class="bx bx-bulb"></i>
                        <span>{{"t2.admin.page_builder.competences"|translate}}</span>
                    </a>
                </li>
                <li class="w-100">
                    <a (click)="openRequestsModal('services')" class="template-request-link nav-link scrollto w-100"><i
                            class="bx bx-briefcase-alt-2"></i>
                        <span>{{"t2.admin.page_builder.service"|translate}}</span>
                    </a>
                </li>
                <li class="w-100">
                    <a (click)="openRequestsModal('diplomas')" class="template-request-link nav-link scrollto w-100"><i
                            class="bx bxs-graduation"></i>
                        <span>{{"t2.admin.page_builder.diplome"|translate}}</span>
                    </a>
                </li>
                <li class="w-100">
                    <a (click)="openRequestsModal('testimonials')" class="template-request-link nav-link scrollto w-100"><i
                            class="bx bxs-user-account"></i>
                        <span>{{"t2.admin.page_builder.testimonials"|translate}}</span>
                    </a>
                </li>
                <!-- <li class="w-100">
                    <a class="nav-link scrollto w-100"><i
                            class="bx bx-envelope"></i>
                        <span>{{"t2.admin.page_builder.contact"|translate}}</span>
                    </a>
                </li> -->
            </ul>
        </nav><!-- .nav-menu -->
    </div>
</div>


<ng-template #requestsModal>
    <app-template-requests-edit class="w-100" [appTemplateRequests]="getTemplateRequests(selectedSubCategory)"
        [subCategory]="('req.background'|translate)" [application]="application" [size]="'md'"
        (templateRequestsChange)="templateRequestsChange">
    </app-template-requests-edit>
</ng-template>
