<head>
  <link rel="stylesheet" href="https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css">
  <link rel="stylesheet" href="https://dev.env.pirog.io/t4/assets/scss/styles.css">
  <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.1/css/all.min.css">
</head>

<div class="master-container">
  <div class="sidebar">
    <div class="logo-details">
      <img src="./assets/img/pirog/logo.png" class="side-logo logo_name">
      <i class="bx bx-menu" id="btn"></i>
    </div>
    <ul class="nav-list h-50 template-request-link" (click)="openRequestsModal('social-medias')">
      <li>
        <a>
          <i class='bx bxl-facebook'></i>
          <span class="links_name">Facebook</span>
        </a>
        <span class="tooltip">Facebook</span>
      </li>
      <li>
        <a>
          <i class='bx bxl-twitter'></i>
          <span class="links_name">X</span>
        </a>
        <span class="tooltip">X</span>
      </li>
      <li>
        <a>
          <i class='bx bxl-linkedin'></i>
          <span class="links_name">LinkedIn</span>
        </a>
        <span class="tooltip">LinkedIn</span>
      </li>
      <li>
        <a>
          <i class='bx bxl-instagram'></i>
          <span class="links_name">Instagram</span>
        </a>
        <span class="tooltip">Instagram</span>
      </li>
    </ul>
  </div>
  <section class="home-section">
    <div class="main-container">

      <div class="row justify-content-center ">

        <a (click)="openRequestsModal('logo')" class="text-center my-0 my-sm-3 template-request-link">
          <img src="https://dev.env.pirog.io/t4/assets/img/pirog/logo_white.png" class="main-logo">
        </a>
      </div>

      <div class="h-50 d-flex align-items-center justify-content-center flex-column mt-3 ml-6">
        <div class="mb-3">
          <div class="d-flex align-items-center">
            <img *ngIf=" robotImage; else defaultImage"
              [src]="'https://'+application.name+'.'+pageBuilderUrl+'/'+robotImage"
              (click)="openRequestsModal('chat-robot')" alt="chatbot-img"
              class="mr-3 mb-2 avatar rounded-circle avatar-xl themed-text my-0 my-sm-3 template-request-link"
              width="70px">
            <ng-template #defaultImage>
              <img src="https://dev.env.pirog.io/t4/assets/img/client/robotImage.png"
                (click)="openRequestsModal('chat-robot')" alt="chatbot-img"
                class="mr-3 mb-2 avatar rounded-circle avatar-xl themed-text my-0 my-sm-3 template-request-link"
                width="70px">
            </ng-template>

            <div class="text-center">
              <h3 class="text-left themed-text my-0 my-sm-3 template-request-link"
                (click)="openRequestsModal('chat-robot')">{{robotName}}</h3>
              <h3 class="themed-text my-0 my-sm-3 template-request-link" (click)="openRequestsModal('chat-robot')">
                {{welcome_message}}</h3>
            </div>

          </div>
        </div>
        <div class="mb-3">
          <div class="d-flex align-items-center">
            <img *ngIf=" userImage; else defaultUserImage"
              [src]="'https://'+application.name+'.'+pageBuilderUrl+'/'+userImage"
              (click)="openRequestsModal('chat-user')" alt="chatbot-img"
              class="mr-3 mb-2 avatar rounded-circle avatar-xl themed-text my-0 my-sm-3 template-request-link"
              width="70px">

            <ng-template #defaultUserImage>
              <img src="https://dev.env.pirog.io/t4/assets/img/client/userImage.png"
                (click)="openRequestsModal('chat-user')" alt="chatbot-img"
                class="mr-3 mb-2   avatar rounded-circle avatar-xl themed-text my-0 my-sm-3 template-request-link"
                width="70px">

            </ng-template>

            <div class="text-center">
              <h3 class="text-left themed-text my-0 my-sm-3 template-request-link"
                (click)="openRequestsModal('chat-user')">{{userName}}</h3>
              <h3 class="themed-text my-0 my-sm-3 ">{{'template_request.t4_userName.value'| translate}}</h3>
            </div>
          </div>
        </div>
      </div>

      <!-- Ajout en bas à droite -->

      <!-- Typing container -->
      <div class="typing-container">
        <div class="row justify-content-center w-100 typing-content">
          <div class="col-md-5 col-xs-6">
            <div class="typing-textarea">
              <textarea #chatInput disabled spellcheck="false" placeholder="{{'template_request.t4_placeholder_input.value'| translate}}"></textarea>
              <span id="send-btn" class="cursor-pointer"><i class="fa-regular fa-paper-plane"></i></span>
            </div>
          </div>
          <div class="col-md-2 col-xs-6">

            <div class="typing-controls">
              <span id="theme-btn" class="cursor-pointer"><i class="fa-regular fa-lightbulb"></i></span>
              <span id="delete-btn" class="cursor-pointer"><i class="fa-solid fa-delete-left"></i></span>
            </div>
          </div>
        </div>
      </div>

    </div>

  </section>
</div>



<ng-template #requestsModal>
  <app-template-requests-edit class="w-100" [appTemplateRequests]="getTemplateRequests(selectedSubCategory)"
    [subCategory]="('req.background'|translate)" [application]="application" [size]="'md'"
    (templateRequestsChange)="templateRequestsChange">
  </app-template-requests-edit>
</ng-template>
