import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {PlatformService} from "src/app/services/plateform.service";
import {SeoService} from "src/app/services/seo.service";

@Component({
  selector: "app-console-public",
  templateUrl: "applications.component.html",
  styleUrls: ["./applications.component.scss"]
})

export class ApplicationsComponent implements OnInit {
  isSubscribing = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private seoService: SeoService,
    private platformService: PlatformService) {
    this.activatedRoute.queryParams.subscribe(params => {
      let isSubscriptionSuccessful = params['subscription_success'];

      if (isSubscriptionSuccessful && this.platformService.isPlatformBrowser()) {
        this.isSubscribing = true;
        setTimeout(() => {
          this.router.navigate([], {
            queryParams: {
              'subscription_success': null
            },
            queryParamsHandling: 'merge'
          }).then(_ => {
            window.location.reload();
          })


        }, 3000);
      }
    });
  }

  ngOnInit() {
    //debugger;
    this.seoService.setSeoTags({
      titleKey: "seo.console.title",
      descriptionKey: "seo.console.description",
      noindex: true
    });
  }

}
