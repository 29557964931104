<div class="row">
    <div class="col-12">
        <div *ngIf="['piFunction', 'piChat'].includes(application.template.templateType)">
            <app-inputs-table *ngIf="templateRequests" 
                [categories]="['TEMPLATE']" 
                [application]='application'
                [templateRequests]="templateRequests" 
                [selectedTemplateRequest]='selectedTemplateRequest'>
            </app-inputs-table>
        </div>
        
        <div *ngIf="['piFunction','piTransfer','piProfile','piChat'].includes(application.template.templateType)">
            <app-inputs-table *ngIf="templateRequests" 
                [categories]="['INTERFACE']" 
                [application]='application'
                [templateRequests]="templateRequests" 
                [selectedTemplateRequest]='selectedTemplateRequest'>
            </app-inputs-table>
        </div>
    </div>
</div>