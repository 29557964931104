import { Component, Input} from '@angular/core';

import { Application } from 'src/app/models/application.model';

@Component({
  selector: 'app-application-requests',
  templateUrl: './application-requests.component.html',
  styleUrls: ['./application-requests.component.scss']
})
export class ApplicationRequestsComponent {

  @Input() application: Application;

}
