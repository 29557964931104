import { Component, HostListener, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { Application } from "src/app/models/application.model";
import { PlatformService } from "src/app/services/plateform.service";
import { clearCart, setTemplateType } from "src/app/store/order/order.actions";
import { selectCurrentApplication } from "src/app/store/repository/repository.selector";
import { environment } from "src/environments/environment";

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isPirogPro?: boolean;
  isActivated?: boolean;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "pianalytics",
    title: "piAnalytics",
    type: "link",
    icontype: "ni-chart-pie-35",
    isPirogPro: true,
    isActivated: true
  },
  {
    path: "public",
    title: "public_overview.pirog_app_explorer",
    type: "link",
    icontype: "ni-spaceship",
    isPirogPro: true,
    isActivated: environment.isExplorerActivated
  }
];

@Component({
  selector: "app-dashboard-sidebar",
  templateUrl: "./dashboard-sidebar.component.html",
  styleUrls: ["./dashboard-sidebar.component.scss"]
})

export class DashboardSidebarComponent implements OnInit {
  application$: Observable<Application>;

  public isMobile: any;
  public menuItems: any[];
  public isCollapsed = false;
  public templateType: string;
  isSubscribedToPirogPro$: Observable<boolean>;

  constructor(private router: Router, private store: Store, private platformeService: PlatformService){}

  ngOnInit() {
    this.isMobile=false;
    if(this.platformeService.isPlatformBrowser()){
      this.isMobile = window.innerWidth < 1200;
    }
    this.initData();

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if(this.platformeService.isPlatformBrowser()){
      if ((window.innerWidth < 1200) && (misc.sidebar_mini_active )) {
        this.minimizeSidebar();
        this.isMobile = true;
      }

      if ((window.innerWidth >= 1200) && (misc.sidebar_mini_active == false)) {
        this.minimizeSidebar();
        this.isMobile = false;
      }
    }
  }

  private initData(): void {
    this.application$ = this.store.select(selectCurrentApplication);
    this.application$.subscribe(
      response => {
        if(response !== null) {
          this.templateType = response.template.templateType;
          this.menuItems = ROUTES;
          this.router.events.subscribe(event => {
            this.isCollapsed = true;
          });
        }
      })
    }

  onMouseEnterSidenav() {
    if(this.platformeService.isPlatformBrowser()){
      if (!document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.add("g-sidenav-show");
      }
    }
  }

  onMouseLeaveSidenav() {
    if(this.platformeService.isPlatformBrowser()){
      if (!document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-show");
      }
    }
  }

  minimizeSidebar() {
    if(this.platformeService.isPlatformBrowser()){
      const sidenavToggler = document.getElementsByClassName(
        "sidenav-toggler"
      )[0];
      const body = document.getElementsByTagName("body")[0];
      if (body.classList.contains("g-sidenav-pinned")) {
        misc.sidebar_mini_active = true;
      } else {
        misc.sidebar_mini_active = false;
      }
      if (misc.sidebar_mini_active === true) {
        body.classList.remove("g-sidenav-pinned");
        body.classList.add("g-sidenav-hidden");
        sidenavToggler.classList.remove("active");
        misc.sidebar_mini_active = false;
      } else {
        body.classList.add("g-sidenav-pinned");
        body.classList.remove("g-sidenav-hidden");
        sidenavToggler.classList.add("active");
        misc.sidebar_mini_active = true;
      }
    }
  }

  redirectToOrder() {
    this.store.dispatch(clearCart());
    this.store.dispatch(setTemplateType({templateType: this.templateType}));
    this.router.navigate(['/store', this.templateType, 'order']);
  }

  public endTemplateName(string) {
    return string.substring(2,string.length);
  }
}
