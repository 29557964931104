import {Component, OnInit} from "@angular/core";
import {PlatformService} from "../../../services/plateform.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {ApplicationService} from "../../../services/application.service";
import {Application} from "../../../models/application.model";
import {Observable} from "rxjs";
import {
  loadSelectedApplication,
  loadSelectedApplicationIsConfigComplete
} from "../../../store/repository/application/application-repository.actions";
import {
  selectCurrentApplication,
  selectCurrentApplicationIsConfigComplete
} from "../../../store/repository/repository.selector";

@Component({
  selector: "app-dashboard-layout",
  templateUrl: "./application-layout.component.html",
  styleUrls: ["./application-layout.component.scss"]
})
export class ApplicationLayoutComponent implements OnInit {

  constructor(public plateformService: PlatformService, private router: Router,
              private activatedRoute: ActivatedRoute,
              private store: Store,
              private applicationService: ApplicationService) {

  }

  idApplication: string | null = null;

  application: Application;
  isConfigComplete$: Observable<boolean>;
  application$: Observable<Application>;

  ngOnInit(): void {
    this.getParamsFromRoute();
  }

  private getParamsFromRoute(): void {
    //console.log('activatedRoute', this.activatedRoute.snapshot);
    this.activatedRoute.params.subscribe(params => {
      this.idApplication = params['applicationId'];
      if (this.idApplication) {
        this.store.dispatch(loadSelectedApplication({idApplication: this.idApplication}));
        this.store.dispatch(loadSelectedApplicationIsConfigComplete({idApplication: this.idApplication}));
        this.initData();
      }
    })
  }

  private initData(): void {
    this.application$ = this.store.select(selectCurrentApplication);
    this.isConfigComplete$ = this.store.select(selectCurrentApplicationIsConfigComplete);
  }

  ngOnDestroy() {
  }

}
