import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Application } from 'src/app/models/application.model';
import { SubscriptionDetails } from 'src/app/models/subscription-details.model';
import { updateApplication } from 'src/app/models/update-application-model';
import { ApplicationService } from 'src/app/services/application.service';
import { BillingService } from 'src/app/services/billing.service';
import { SeoService } from 'src/app/services/seo.service';
import { selectCurrentApplication, selectCurrentApplicationIsConfigComplete } from 'src/app/store/repository/repository.selector';
import { environment } from 'src/environments/environment';
import swal from "sweetalert2";

@Component({
  selector: 'app-application-overview',
  templateUrl: 'application-overview.component.html',
})
export class ApplicationOverviewComponent implements OnInit, OnDestroy {
  application$: Observable<Application>;
  isConfigComplete$: Observable<boolean>;
  stripeUrl: string;
  subscription: SubscriptionDetails;
  application: Application;
  isPublic: boolean;
  private _subscriptions: Subscription = new Subscription();
  applicationFormGroup: FormGroup;

  constructor(
    private store: Store,
    private router: Router,
    private billingService: BillingService,
    private applicationService: ApplicationService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private seoService: SeoService) {
    this.stripeUrl = environment.stripePortalUrl;
  }

  ngOnInit(): void {
    this.initData();
    this.initForm();
    this.seoService.setSeoTags({ titleKey: "seo.overview.title", descriptionKey: "seo.overview.description", noindex: true });
  }
  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  private initForm(): void {
    this.applicationFormGroup = this.fb.group({
      publicHeadline: ['', Validators.required],
      publicDescription: ['', Validators.required],
      publicImage: ['', Validators.required]
    });
    this.applicationFormGroup.valueChanges.subscribe(() => {
      this.updateApplication();
    });
  }
  private updateApplication(): void {
    const formValues = this.applicationFormGroup.value;

    const request: updateApplication = {
      isPublic: this.isPublic,
      id: this.application.id,
      publicHeadline: formValues.publicHeadline,
      publicDescription: formValues.publicDescription,
    };

    this.applicationService.updateApplication(request, this.application.id).subscribe(() => {
    });
  }
  private initData(): void {
    this.application$ = this.store.select(selectCurrentApplication);
    this.isConfigComplete$ = this.store.select(selectCurrentApplicationIsConfigComplete);

    const _application = this.application$.subscribe((application) => {
      if (!application) return;
      this.application = application;
      this.isPublic = this.application.public;
      this.applicationFormGroup.patchValue({
        publicHeadline: this.application.publicHeadline,
        publicDescription: this.application.publicDescription,
      });
      this.billingService.getSubscriptionDetails(application.subscriptionId).subscribe(response => {
        this.subscription = response;
      });
    });
    this._subscriptions.add(_application);
  }

  completePayment() {
    this.applicationService.completePaymentByApplicationId(this.application.id).subscribe();
  }

  public booleanTemplateRequestChanged(value): void {
    const formValues = this.applicationFormGroup.value;
    this.isPublic = value.target.checked;
    const request: updateApplication = {
      isPublic: this.isPublic,
      id: this.application.id,
      publicHeadline: formValues.publicHeadline,
      publicDescription: formValues.publicDescription,
    };
    this.applicationService.updateApplication(request, this.application.id).subscribe();
  }

  deleteApplication(id: string) {
    swal.fire({
      title: this.translateService.instant("swal.deleteButton.title"),
      text: this.translateService.instant("swal.deleteButton.text"),
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-danger",
        cancelButton: "btn btn-primary"
      },
      confirmButtonText: this.translateService.instant("swal.deleteButton.confirmButtonText"),
      cancelButtonText: this.translateService.instant("swal.deleteButton.cancelButtonText"),
      showCancelButton: true
    }).then((result) => {
      if (result.value) {
        this.applicationService.deleteApplicationById(id).subscribe(_ => {
          swal.fire({
            title: this.translateService.instant("swal.deleteButton.after_title"),
            text: this.translateService.instant("swal.deleteButton.after_text"),
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-primary"
            }
          });
          this.router.navigate(['/dashboard/applications']);
        })
      }
    });
  }

}
