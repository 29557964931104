import {
  Component,
  AfterViewChecked,
  HostListener,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NgxFileDropEntry,
  FileSystemFileEntry
} from 'ngx-file-drop';
import { selectIsUserSubscribedToPirogPro } from 'src/app/store/auth/auth.selectors';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Application } from 'src/app/models/application.model';
import { ListObjectsV2CommandOutput } from '@aws-sdk/client-s3';
import { S3ServiceService } from 'src/app/services/s3-service.service';
import { TemplateT3Service } from 'src/app/services/template.t3.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CheckEmailAlreadyExist } from 'src/app/utils/form-validators';
import { Clipboard } from '@angular/cdk/clipboard';
import { Store } from '@ngrx/store';
import swal from 'sweetalert2';
import {
  ElementDTO,
  UserFile,
  UserFileRequest,
  UserFileResponse,
} from 'src/app/models/user-file.model';
import { PlatformService } from 'src/app/services/plateform.service';
import { ToastService } from 'src/app/services/toaster.service';
import { PirogProModalComponent } from '../../../../shared/pirog-pro-modal/pirog-pro-modal.component';

@Component({
  selector: 'app-application-filesystem',
  templateUrl: './application-filesystem.component.html',
  styleUrls: ['./application-filesystem.component.scss'],
})
export class ApplicationFilesystemComponent
  implements OnInit, AfterViewChecked
{
  @ViewChild('pirog_pro_modal') private pirogProModal: PirogProModalComponent;
  @ViewChild('modalForm', { read: TemplateRef }) modalForm: TemplateRef<any>;
  @Input() application: Application;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: 'modal-dialog-centered modal-sm',
  };
  userFileFg!: UntypedFormGroup;
  elementDTO: ElementDTO;

  // data
  bucketObjects: ListObjectsV2CommandOutput;
  data: any[];
  storageSize: number = 0;
  activeData: any[];
  activesCol: number[];
  activesIds: string[];

  // drag & drop
  isSubscribedToPirogPro: boolean;
  dragdropFg: UntypedFormGroup;
  filesPaths: string[];
  activeDragDropPath: string;
  bucketName: string;
  basePath: string;

  // contextMenu
  contextmenu = false;
  contextmenuX = 0;
  contextmenuY = 0;
  fileOrFolderActif: any;

  // modal
  isModalOpen = false;
  isAFolder: boolean = false;
  userFiles: UserFile[] = [];
  // save scroll position on the column
  scrollYPosition: number;
  scroll: boolean;

  @HostListener('window:scroll', ['$event'])
  onScroll(event) {
    if (this.contextmenu) {
      this.isModalOpen = false;
      this.contextmenu = false;
    }
  }

  constructor(
    private store: Store,
    public storageService: S3ServiceService,
    private fb: UntypedFormBuilder,
    private templateT3Service: TemplateT3Service,
    private modalService: BsModalService,
    private clipboard: Clipboard,
    public platformeService: PlatformService,
    private toastService: ToastService,
    private translateService: TranslateService,
  ) {}

  ngOnInit(): void {
    this.bucketName = environment.PITRANSFER_BUCKET;
    this.basePath = `${environment.S3_PREFIXE}/${this.application?.id}`;

    this.store.select(selectIsUserSubscribedToPirogPro).subscribe((x) => {
      this.isSubscribedToPirogPro = x;
    });
    this.initAttribute();
    this.initForms();
    this.getData();
  }

  ngAfterViewChecked() {
    this.setScrollOnActiveCol();
  }

  private setScrollOnActiveCol(): void {
    if (this.platformeService.isPlatformBrowser()) {
      if (this.scroll) {
        let activeColId =
          'col_' + this.activesIds[this.activesIds.length - 1].split('_')[0];
        setTimeout(() => {
          document
            .getElementById(activeColId)
            .scrollTo(0, this.scrollYPosition);
          this.scroll = false;
        }, 1);
        this.scroll = false;
      }
    }
  }

  private initAttribute(): void {
    // filesystem
    this.activesCol = [0];
    this.activesIds = ['0_0'];
    this.scrollYPosition = 0;
    this.scroll = false;

    // drop
    this.filesPaths = [];
  }

  private initForms(): void {
    this.dragdropFg = this.fb.group({
      value: ['', Validators.required],
    });

    this.userFileFg = this.fb.group(
      {
        email: ['', [Validators.email, Validators.required]],
      },
      { validator: CheckEmailAlreadyExist(this.elementDTO?.privateUserFiles) },
    );
  }

  private async getData() {
    this.bucketObjects = await this.storageService.listObjectV2(
      this.basePath,
      this.bucketName
    );
    await this.createTree();
  }

  // ------------------------------
  // STRUCTURE DATA FROM S3
  // ------------------------------

  private async createTree() {
    // given the S3 object from AWS, create the tree of all folder & file
    let s3objects = [];
    let result = [];
    if (this.bucketObjects['Contents']) {
      let prefixeRemover = this.basePath.length;

      // get all filenames & ETag
      this.bucketObjects['Contents'].map((obj) =>
        s3objects.push({
          key: obj['Key'].substring(prefixeRemover),
          ETag: obj['ETag'].replace(/"/g, ''),
          Size: obj['Size'],
        }),
      );
      let level = { result };

      // create tree
      s3objects.forEach((s3object) => {
        this.storageSize = this.storageSize + s3object['Size'];
        s3object['key'].split('/').reduce((r, key, col, path) => {
          if (!r[key]) {
            r[key] = { result: [] };
            let id = col - 1 + '_' + r.result.length;
            r.result.push({
              key: key,
              key_decoded: decodeURI(key),
              children: r[key].result,
              index: r.result.length,
              path: path.slice(0, col + 1).join('/'),
              col: col - 1,
              id: id,
              ETag: s3object['ETag'],
              size: s3object['Size'],
            });
          }
          return r[key];
        }, level);
      });
      // remove the first column with one folder
      result = result[0]['children'];
    } else {
      result = undefined;
    }
    this.storageSize = this.convertBytesIntoGb(this.storageSize);
    this.data = result;
  }

  public subData(index) {
    // select a subpart of this.data
    let i = 0;
    let result = this.data;
    while (i < index) {
      result = result[this.activesCol[i]]['children'];
      i = i + 1;
    }
    return result;
  }

  public createFilePath(index: number) {
    let path = '/';

    if (index >= 1) {
      let subData = this.subData(index);
      return subData[0].path.split('/').slice(0, -1).join('/') + '/';
    }
    return path;
  }

  // ------------------------------
  // LEFT CLICK
  // ------------------------------

  public async onLeftClick(event, fileOrFolder) {
    if (fileOrFolder) {
      this.setColumnScrollPosition(fileOrFolder);
      await this.hooverOnOff('remove');
      await this.updateActivesCol(fileOrFolder.col, fileOrFolder.index);
      await this.hooverOnOff('add');
    } else {
      this.contextmenuX = event.layerX;
      this.contextmenuY = event.layerY;
      this.contextmenu = true;
    }
  }

  private setColumnScrollPosition(fileOrFolder): void {
    // we save the scroll position of the active column to scroll back on ngAfterViewChecked
    if (this.platformeService.isPlatformBrowser()) {
      let col_id = 'col_' + fileOrFolder.col;
      var elementTopScroll = document.getElementById(col_id);
      this.scrollYPosition = elementTopScroll.scrollTop;
      this.scroll = true;
    }
  }

  private async hooverOnOff(action: string) {
    if (this.platformeService.isPlatformBrowser()) {
      // add or remove hoover on the file or folder
      if (action == 'remove') {
        for (let i = 0; i < this.activesIds.length; i++) {
          document.getElementById(this.activesIds[i]).classList.remove('activ');
        }
      } else {
        for (let j = 0; j < this.activesIds.length; j++) {
          document.getElementById(this.activesIds[j]).classList.add('activ');
        }
      }
    }
  }

  private async updateActivesCol(col: number, index: number) {
    // on click, update this.activesCol
    this.activesCol[col] = index;
    while (this.activesCol.length - 1 > col) {
      this.activesCol.pop();
    }
    this.activesCol.push(0);
    this.calculateIdFromActivesCol();
  }

  private calculateIdFromActivesCol() {
    // retreive the html id element of a app-filesystem-folder for the hoover
    let l = [];
    let s = '';
    for (let i = 0; i < this.activesCol.length - 1; i++) {
      s = i + '_' + this.activesCol[i];
      l.push(s);
    }
    this.activesIds = l;
  }

  // ------------------------------
  // REFRESH
  // ------------------------------

  public refresh = (): void => {
    this.disableContextMenu();
    this.ngOnInit();
  };

  public updateActiveCol = (col: string): void => {
    // when we delete a file of folder, we need to update this.activesCol

    let i: number = +col;
    while (i <= this.activesCol.length) {
      this.activesCol.pop();
      i = i + 1;
    }
  };
  // ------------------------------------
  // DRAG & DROP NgxFileDropEntry module
  // ------------------------------------
  public async submitFirstDrop() {
    this.dragdropFg.patchValue({ value: [this.filesPaths.join(', ')] });
    if (this.dragdropFg.valid) {
      let usageValid = await this.isSubscriptionUsageValid(
        this.storageService.filesNgx,
      );
      if (!usageValid) {
        this.fireUsageAlert();
        return;
      }

      let baseSubPath = this.storageService.flattenFiles ? '/Root/' : '/';
      await this.storageService.uploadDroppedFiles(this.bucketName, this.basePath, baseSubPath);
      this.refresh();
    }
  }

  public async firstDrop(files: NgxFileDropEntry[]) {
    // first drop function that upload files by drag & drop into the initial section
    await this.storageService.loadDropFilesNgx(files);
  }

  public dropAndUpload = async (
    filesNgx: NgxFileDropEntry[],
  ): Promise<void> => {

    let usageValid = await this.isSubscriptionUsageValid(filesNgx);
    if (!usageValid) {
      this.fireUsageAlert();
      return;
    }
    await this.storageService.loadDropFilesNgx(filesNgx);
    await this.storageService.uploadDroppedFiles(this.bucketName, this.basePath, this.activeDragDropPath);
    this.refresh();
  };

  private async handleDeleteUserFileByFilePath(filePath: string) {
    let filePathRequest: UserFileRequest = {
      filePath: '/' + filePath,
    };

    //deletealluserfilesbyfilepath
    this.templateT3Service
      .deleteUserFiles(filePathRequest, this.application.id, false, null)
      .subscribe((response) => {});
  }
  // ------------------------------------
  // RIGHT  and LEFT CLICK
  // ------------------------------------
  onLeftAndRightClick(event, fileOrFolder) {
    this.onLeftClick(event, fileOrFolder);
    this.onRightClick(event, fileOrFolder);
  }

  public async createEmptyDirectory(): Promise<void> {
    if (this.platformeService.isPlatformBrowser()) {
      const folderName = window.prompt(
        'Entrez le nom du nouveau dossier :',
        '',
      );
      if (folderName && folderName.trim() !== '') {
        // Créer le chemin complet du nouveau dossier
        const newFolderPath = this.basePath + '/' + folderName + '/';
        await this.storageService.createEmptyDirectory(newFolderPath, this.bucketName);
        this.refresh();
      }
    }
  }

  // ------------------------------------
  // RIGHT CLICK
  // ------------------------------------

  onRightClick(event, fileOrFolder = undefined) {
    this.isAFolder = fileOrFolder.children.length != 0;

    event.stopPropagation();
    event.preventDefault();

    if (fileOrFolder != undefined) {
      this.fileOrFolderActif = fileOrFolder;
    }
    if (event.target.classList[0] == 'col-filesystem') {
      this.fileOrFolderActif = undefined;
    }
    this.contextmenuX = event.layerX;
    this.contextmenuY = event.layerY;
    this.contextmenu = true;
  }

  disableContextMenu = (): void => {
    this.contextmenu = false;
  };

  public fileOver = (event, destination): void => {
    // on file hoover, we set this.activeDragDropPath before droping into it.
    event.stopPropagation();
    event.preventDefault();
    if (destination == 'column') {
      this.activeDragDropPath = this.createFilePath(
        event.target.lastElementChild.id,
      );
    } else {
      // row
      this.activeDragDropPath = event.target.id + '/';
    }
  };

  // ------------------------------------
  // MODAL SHARE
  // ------------------------------------

  public openFormModal = (): void => {
    this.getListUserFile();
    this.formModal = this.modalService.show(this.modalForm, this.form);
    this.contextmenu = false;
  };

  public closeFormModal(): void {
    this.contextmenu = false;
    this.userFileFg.reset();
    this.elementDTO = undefined;
    this.modalService.hide();
  }

  private getListUserFile(): void {
    const userFileRequest: UserFileRequest = {
      filePath: this.fileOrFolderActif.path,
    };

    this.templateT3Service
      .getElement(userFileRequest, this.application.id)
      .subscribe((response) => {
        this.elementDTO = response;
      });
  }

  public addUserFile() {
    //FOLDER
    if (this.isAFolder && this.userFileFg.valid) {
      this.createUserFilesByFolder();
    }
    //FILE
    else if (
      !this.elementDTO.isAParentPrivatelyShared &&
      this.userFileFg.valid
    ) {
      this.createUserFileByFile();
    }
  }

  public removeUserFile(userFile: UserFileResponse) {
    const onDelete = () => {
      this.userFileFg.reset();
      this.getListUserFile();
    };

    if (this.isAFolder) {
      const userFileRequest: UserFileRequest = {
        filePath: userFile.filePath,
        email: userFile.email,
      };

      this.templateT3Service
        .deleteUserFiles(userFileRequest, this.application.id, true, null)
        .subscribe(onDelete);
    } else {
      this.templateT3Service.deleteUserFile(userFile.id).subscribe(onDelete);
    }
  }

  public async setUserFilesPublic() {
    this.userFiles = [];

    if (this.elementDTO?.isItselfOrAChildPrivatelyShared) {
      const userClickOk = await this.modalDeleteUserFile(false, false);
      if (!userClickOk) return;
    }

    if (this.elementDTO?.isAChildPublicyShared) {
      const userFileRequest: UserFileRequest = {
        filePath: this.fileOrFolderActif.path,
      };
      this.templateT3Service
        .deleteUserFiles(userFileRequest, this.application.id, true, true)
        .subscribe(() => this.addUserFileRequestForActiveElementAndUpsert());
    } else {
      this.addUserFileRequestForActiveElementAndUpsert();
    }
  }

  public async setUserFilesRestricted() {
    this.userFiles = [];
    if (
      this.isAFolder &&
      this.elementDTO?.isItselfOrAChildPublicyShared &&
      !this.elementDTO?.isDirectlyPublicyShared
    ) {
      var userClickOk = await this.modalDeleteUserFile(true, false);
      if (!userClickOk) {
        return;
      }
    }
    this.deletePublicUserFilesAndRefresh();
  }

  public addUserFileRequestForActiveElementAndUpsert() {
    this.addUserFileRequestForActiveFileOrFolder(true, this.isAFolder);

    this.templateT3Service
      .upsertUserFiles(this.userFiles)
      .subscribe((response) => {
        this.initForms();
        this.getListUserFile();
      });
  }

  public deletePublicUserFilesAndRefresh() {
    let userFileRequest: UserFileRequest = {
      filePath: this.fileOrFolderActif.path,
    };
    this.templateT3Service
      .deleteUserFiles(userFileRequest, this.application.id, true, true)
      .subscribe((response) => {
        this.initForms();
        this.getListUserFile();
      });
  }

  public async handleGetObject(fileOrFolder) {
    let url = null;
    // it's a file
    if (!this.isAFolder) {
      url = await this.storageService.getObject(
        this.basePath + fileOrFolder.path,
        24 * 3600,
        this.bucketName
      );
    }
    this.clipboard.copy(url);
    let message = this.translateService.instant('filesystem.toastr_link');
    this.toastService.success(message);
  }

  // This function handles arrays and objects
  private getAllChildrensPath(obj) {
    let childrenPaths: Object[] = [];
    for (var k in obj) {
      if (typeof obj[k] == 'object' && obj[k]['children'].length !== 0)
        this.getAllChildrensPath(obj[k]['children']);
      else {
        childrenPaths.push({ Key: this.basePath + obj[k]['path'] });
      }
    }
    return childrenPaths;
  }

  //-----------------------------------------------------------------------
  //------------------------------ UTILS -----------------------------------
  //-------------------------------------------------------------------------

  getKeys(obj: any): Array<string> {
    return Object.keys(obj);
  }

  private convertBytesIntoGb(bytesValue: number) {
    return bytesValue / (1024 * 1024 * 1024);
  }

  // private calculFilesSizeFirstDrop = async (filesList:File[]): Promise<number> => {
  //   console.log('filesList : ',  filesList);
  //   return new Promise<number>((resolve, reject) => {
  //     let bytesValue = 0;
  //     filesList.forEach( file => {
  //       console.log('file.size : ',  file.size)
  //       bytesValue += file.size;
  //     })
  //       resolve(bytesValue);
  //   });
  // }

  private calculFilesSizeDrop = async (
    filesList: NgxFileDropEntry[],
  ): Promise<number> => {
    return new Promise<number>((resolve, reject) => {
      let bytesValue: number = 0;
      let promises: Promise<void>[] = [];

      for (let i = 0; i < filesList.length; i++) {
        let droppedFile = filesList[i];
        if (droppedFile.fileEntry.isFile) {
          let fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

          // Wrap the file processing in a promise
          let filePromise = new Promise<void>((resolveFile) => {
            fileEntry.file((file: File) => {
              bytesValue += file.size;
              resolveFile();
            });
          });

          promises.push(filePromise);
        }
      }

      // Wait for all file promises to resolve
      Promise.all(promises)
        .then(() => resolve(bytesValue))
        .catch((err) => reject(err));
    });
  };

  private calculFilesSize = async (
    filesList: NgxFileDropEntry[],
  ): Promise<number> => {
    let bytesValue: any;

    bytesValue = await this.calculFilesSizeDrop(filesList);

    return new Promise<number>(async (resolve, reject) => {
      let gbValue = this.convertBytesIntoGb(bytesValue);
      resolve(gbValue);
    });
  };

  public fireUsageAlert = (): void => {
    swal
      .fire({
        title: this.translateService.instant(
          'swal.piTransferStorageLimit.title',
        ),
        text: this.translateService.instant('swal.piTransferStorageLimit.text'),
        buttonsStyling: false,
        customClass: {
          cancelButton: 'btn btn-danger',
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: this.translateService.instant(
          'swal.piTransferStorageLimit.confirmButtonText',
        ),
        cancelButtonText: this.translateService.instant(
          'swal.piTransferStorageLimit.cancelButtonText',
        ),
        showCancelButton: true,
      })
      .then((result) => {
        if (result.value) {
          this.pirogProModal.open();
        }
      });
  };

  private isSubscriptionUsageValid = async (
    filesList: NgxFileDropEntry[],
  ): Promise<boolean> => {
    return new Promise<boolean>(async (resolve, reject) => {
      let filesListSize = 0;
      let usageValid: boolean = true;

      if (!this.isSubscribedToPirogPro) {
        filesListSize = await this.calculFilesSize(filesList);
        let futureSize = this.storageSize + filesListSize;
        if (futureSize > environment.PI_TRANSFER_GB_LIMIT) {
          usageValid = false;
        }
      }
      resolve(usageValid);
    });
  };

  private addUserFileRequestForChildren(children, parentPath, isPublic) {
    for (const child of children) {
      const filePath = parentPath + '/' + child.key;
      this.userFiles.push({
        applicationId: this.application.id,
        email: this.userFileFg.value.email,
        etag: this.fileOrFolderActif.ETag,
        filePath: filePath,
        isPublic: isPublic,
        isAFolder: child.children.length > 0,
        size: child.size,
      });

      if (child.children.length > 0) {
        this.addUserFileRequestForChildren(child.children, filePath, isPublic);
      }
    }
  }

  private addUserFileRequestForActiveFileOrFolder(isPublic, isAFolder) {
    this.userFiles.push({
      applicationId: this.application.id,
      email: this.userFileFg.value.email,
      etag: this.fileOrFolderActif.ETag,
      filePath: this.fileOrFolderActif.path,
      isPublic: isPublic,
      isAFolder: isAFolder,
      size: this.fileOrFolderActif.size,
    });
  }

  private addUserFilesToFolder() {
    this.userFiles = [];
    //let childrenPaths = this.fileOrFolderActif.children;
    //this.addUserFileRequestForChildren(childrenPaths, this.fileOrFolderActif.path, false);
    this.addUserFileRequestForActiveFileOrFolder(false, true);
    this.templateT3Service
      .createUserFiles(this.userFiles)
      .subscribe((response) => {
        this.initForms();
        this.getListUserFile();
      });
  }

  private createUserFileByFile() {
    const userFile: UserFile = {
      applicationId: this.application.id,
      email: this.userFileFg.value.email,
      etag: this.fileOrFolderActif.ETag,
      filePath: this.fileOrFolderActif.path,
      isPublic: false,
      isAFolder: false,
      size: this.fileOrFolderActif.size,
    };
    this.templateT3Service.createUserFile(userFile).subscribe((response) => {
      this.userFileFg.reset();
      this.getListUserFile();
    });
  }

  private modalDeleteUserFile(
    isPublic: boolean,
    thenAddUserFilesToFolder: boolean,
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let translateTextKey = isPublic
        ? 'share.textSetPublic'
        : 'share.textSetPrivate';
      swal
        .fire({
          title: this.translateService.instant('share.title'),
          text: this.translateService.instant(translateTextKey),
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-danger',
            cancelButton: 'btn btn-primary',
          },
          confirmButtonText: this.translateService.instant(
            'share.confirmButtonText',
          ),
          cancelButtonText: this.translateService.instant(
            'share.cancelButtonText',
          ),
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            let userFileRequest: UserFileRequest = {
              filePath: this.fileOrFolderActif.path,
            };
            this.templateT3Service
              .deleteUserFiles(
                userFileRequest,
                this.application.id,
                true,
                isPublic,
              )
              .subscribe({
                next: () => {
                  if (thenAddUserFilesToFolder) {
                    this.addUserFilesToFolder();
                  }
                  resolve(true); // Resolve the promise after successful deletion and creation of new files
                },
                error: (error) => {
                  reject(error); // Reject the promise if there's an error
                },
              });
          } else {
            resolve(false); // Resolve the promise if the user cancels the action
          }
        })
        .catch((error) => {
          reject(error); // Reject the promise if there's an error with swal
        });
    });
  }

  private async createUserFilesByFolder() {
    if (!this.userFileFg.valid) {
      return;
    }
    // si le dossier a des enfants privés et n'est pas partagé lui même
    if (
      this.elementDTO.isAChildPrivatelyShared &&
      !this.elementDTO.isDirectlyPrivatelyShared
    ) {
      await this.modalDeleteUserFile(null, true);
    } else {
      this.addUserFilesToFolder();
    }
  }

  isActivated(id: string): boolean {
    return this.activesIds.includes(id);
  }

  toggleActivation(id: string): void {
    if (this.activesIds.includes(id)) {
      // Si déjà actif, désactive
      this.activesIds = this.activesIds.filter((activeId) => activeId !== id);
    } else {
      // Si inactif, active
      this.activesIds.push(id);
    }
  }
  //-------------------------------------------------------------------------
  //------------------------------ UNUSED -----------------------------------
  //-------------------------------------------------------------------------

  onThreeDotsClick(event, fileOrFolder = undefined) {
    this.isAFolder = fileOrFolder.children.length != 0;

    event.stopPropagation();
    event.preventDefault();
    if (fileOrFolder != undefined) {
      this.fileOrFolderActif = fileOrFolder;
    }
    if (event.target.classList[0] == 'col-filesystem') {
      this.fileOrFolderActif = undefined;
    }
    this.contextmenuX = event.layerX;
    this.contextmenuY = event.layerY;
    this.contextmenu = true;
  }
}
