import { Component, Input, OnInit } from '@angular/core';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';
import { TemplateService } from 'src/app/services/template.service';


@Component({
  selector: 'app-application-template-requests',
  templateUrl: './application-template-requests.component.html',
  styleUrls: ['./application-template-requests.component.scss']
})
export class ApplicationTemplateRequestsComponent implements OnInit {

  @Input() application: Application;

  templateRequests: ApplicationTemplateRequest[];

  selectedTemplateRequest: ApplicationTemplateRequest;

  constructor(
    private templateService: TemplateService,
  ) { }

  ngOnInit(): void {
    this.getData();
  }

  private getData(): void {
    this.templateService
      .getTemplateRequests(this.application.id)
      .subscribe((response) => {
        this.templateRequests = response;
      });
  }
}
