import { Injectable } from '@angular/core';
import {
  S3Client,
  GetObjectCommand,
  PutObjectCommand,
  ListObjectsV2Command,
  DeleteObjectCommand,
  DeleteObjectsCommand,
  CopyObjectCommand,
  HeadObjectCommand,
  CreateMultipartUploadCommand,
  UploadPartCommand,
  AbortMultipartUploadCommand,
  CompleteMultipartUploadCommand,
} from '@aws-sdk/client-s3';
import { environment } from 'src/environments/environment';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Injectable({
  providedIn: 'root',
})
export class S3ServiceService {
  public _bucket: S3Client;
  public _basePath: string;
  public _baseSubPath: string;
  public _partSize: number;

  files: File[] = [];
  filesNgx: NgxFileDropEntry[] = [];
  flattenFiles = true;
  uploadProgress: { [key: string]: number } = {};
  overallProgress: number = 0;

  constructor() {
    this._bucket = new S3Client({
      credentials: {
        accessKeyId: environment.AWS_ACCESS_KEY_ID,
        secretAccessKey: environment.AWS_SECRET_ACCESS_KEY,
      },
      region: environment.AWS_REGION,
    });
    this._basePath = '';
    this._baseSubPath = '';
    this._partSize = 5 * 1024 * 1024; // 5 MB
  }

  public set basePath(newBasePath: string) {
    this._basePath = newBasePath;
  }

  private resetAttributes() {
    this.files = [];
    this.filesNgx = [];
    this.uploadProgress = {};
    this.overallProgress = 0;
    this._baseSubPath = '';
    this.flattenFiles = true;
  }

  public async uploadDroppedFiles(
    bucketName: string,
    basePath: string,
    baseSubPath?: string,
  ): Promise<void> {
    if (baseSubPath) {
      this._baseSubPath = baseSubPath;
    }

    for (const fileNgx of this.filesNgx) {
      await this.uploadDroppedNgxFileMultipart(fileNgx, basePath, bucketName);
    }
    this.resetAttributes();
  }

  async uploadSelectedFiles(
    bucketName: string,
    basePath: string,
    baseSubPath?: string,
  ) {
    if (baseSubPath) {
      this._baseSubPath = baseSubPath;
    }

    for (const file of this.files) {
      await this.uploadSelectedFileMultipart(file, basePath, bucketName);
    }
    this.resetAttributes();
  }

  calculateOverallProgress() {
    const totalFiles = Object.keys(this.uploadProgress).length;
    const totalProgress = Object.values(this.uploadProgress).reduce(
      (acc, progress) => acc + progress,
      0,
    );
    return totalProgress / totalFiles;
  }

  updateOverallProgress() {
    this.overallProgress = this.calculateOverallProgress();
  }

  public async loadDropFilesNgx(filesNgx: NgxFileDropEntry[]): Promise<void> {
    // Create an array to hold all file processing promises
    const filePromises: Promise<void>[] = [];

    try {
      this.resetAttributes();

      for (const fileNgx of filesNgx) {
        if (fileNgx.fileEntry.isFile) {
          const fileEntry = fileNgx.fileEntry as FileSystemFileEntry;

          // Push each file processing into the filePromises array
          const filePromise = new Promise<void>((resolve, reject) => {
            fileEntry.file(
              (file: File) => {
                if (file.size > 0) {
                  // Only add files with size greater than 0
                  this.filesNgx.push(fileNgx);
                  this.uploadProgress[fileNgx.relativePath] = 0;
                  //console.log('Processed file:', fileNgx);

                  if (fileNgx.relativePath.indexOf('/') != -1) {
                    this.flattenFiles = false;
                  }
                  resolve(); // Resolve this file's promise after processing
                } else {
                  console.warn(`Skipping empty file: ${file.name}`);
                  resolve(); // Resolve even if we skip the file
                }
              },
              (error) => reject(error),
            ); // Catch file access error
          });

          filePromises.push(filePromise);
        }
      }

      // Wait until all file promises are resolved
      await Promise.all(filePromises);
    } catch (error) {
      console.error('Error in loadDropFilesNgx:', error);
      throw error; // Rethrow error to allow calling function to handle it
    }
  }

  loadFiles(files: File[]): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.resetAttributes();
        for (const file of files) {
          // Folder or File are same in s3, just a path question.
          this.files.push(file);
          this.uploadProgress[file.name] = 0;
        }
        resolve(); // Resolve the promise once all files are processed
      } catch (error) {
        reject(error); // Reject the promise if there's an error
      }
    });
  }

  // todo
  private calculatePathFromGgx(fileNgx: NgxFileDropEntry, basePath: string) {
    let filePath = fileNgx.relativePath;
    return this.calculateS3FilePath(filePath, basePath);
  }

  private calculateS3FilePath(relativePath: string, basePath: string) {
    let filePath = basePath + this._baseSubPath + relativePath;
    return filePath;
  }

  async createEmptyDirectory(
    directoryName: string,
    bucketName: string,
  ): Promise<void> {
    const params = {
      Bucket: bucketName,
      Key: directoryName,
      Body: '',
    };
    const response = await this._bucket.send(new PutObjectCommand(params));
  }

  async uploadFile(file: File, fileName: string, bucketName: string) {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      //ACL: 'public-read',
      ContentType: file.type,
    };

    try {
      const response = await this._bucket.send(new PutObjectCommand(params));
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async uploadFiles(files: FileList, fileNames: string[], bucketName: string) {
    for (let i = 0; i < files.length; i++) {
      await this.uploadFile(files[i], fileNames[i], bucketName);
    }
  }

  async uploadFileDropEntry(file: File, fileName: string, bucketName: string) {
    const params = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      //ACL: 'public-read',
      ContentType: file.type,
    };

    try {
      const response = await this._bucket.send(new PutObjectCommand(params));
    } catch (error) {}
  }

  async listObjectV2(prefix: string, bucketName: string): Promise<any> {
    const input = {
      Bucket: bucketName,
      Prefix: prefix,
    };
    const command = new ListObjectsV2Command(input);

    let response: any;
    try {
      response = await this._bucket.send(command);
      console.log('response : ', response);
    } catch (error) {}
    return response;
  }

  async headObject(key: string, bucketName: string): Promise<any> {
    const input = {
      Bucket: bucketName,
      Key: key,
      ContentType: 'img/png',
    };
    const command = new HeadObjectCommand(input);

    let response: any;
    try {
      response = await this._bucket.send(command);
    } catch (error) {}
    return response;
  }

  async deleteObject(key: string, bucketName: string): Promise<any> {
    const input = {
      Bucket: bucketName,
      Key: key,
    };
    const command = new DeleteObjectCommand(input);
    let response: any;
    try {
      response = await this._bucket.send(command);
    } catch (error) {}
    return response;
  }

  async deleteObjects(keyList: any, bucketName: string): Promise<any> {
    const input = {
      Bucket: bucketName,
      Delete: {
        Objects: keyList,
      },
    };

    const command = new DeleteObjectsCommand(input);
    let response: any;
    try {
      response = await this._bucket.send(command);
    } catch (error) {}
    return response;
  }

  async getObject(
    key: string,
    expiresSecond: number,
    bucketName: string,
  ): Promise<Blob> {
    const input = {
      Bucket: bucketName,
      Key: key,
    };
    const command = new GetObjectCommand(input);
    let url: any;
    try {
      url = await getSignedUrl(this._bucket, command, {
        expiresIn: expiresSecond,
      });
    } catch (error) {}
    return url;
  }

  public async mooveObject(
    keySource: string,
    keyTarget: string,
    bucketName: string,
  ): Promise<any> {
    const copyInput = {
      Bucket: bucketName,
      CopySource: bucketName + '/' + encodeURI(keySource),
      Key: keyTarget,
    };

    const copyCommand = new CopyObjectCommand(copyInput);
    let copyResponse: any;

    try {
      copyResponse = await this._bucket.send(copyCommand);
    } catch (error) {
      var response = await this.headObject(keySource, bucketName);
      throw error;
    }
    return copyResponse;
  }

  // todo
  public uploadDroppedNgxFileMultipart(
    fileNgx: NgxFileDropEntry,
    basePath: string,
    bucketName: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const fileSystem = fileNgx.fileEntry as FileSystemFileEntry;

      fileSystem.file(async (file: File) => {
        let key = this.calculatePathFromGgx(fileNgx, basePath);
        let relativePath = fileNgx.relativePath;
        this.uploadFileMultipart(
          file,
          key,
          relativePath,
          bucketName,
          resolve,
          reject,
        );
      });
    });
  }

  // todo
  public uploadSelectedFileMultipart(
    file: File,
    basePath: string,
    bucketName: string,
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      let key = this.calculateS3FilePath(file.name, basePath);
      let relativePath = file.name;
      this.uploadFileMultipart(
        file,
        key,
        relativePath,
        bucketName,
        resolve,
        reject,
      );
    });
  }

  // MULTIPART
  public async uploadFileMultipart(
    file: File,
    key: string,
    relativePath: string,
    bucketName: string,
    resolve,
    reject,
  ) {
    const numParts = Math.ceil(file.size / this._partSize);

    let uploadId: string | undefined;
    let multipartMap: { Parts: { ETag: string; PartNumber: number }[] } = {
      Parts: [],
    };

    try {
      // Step 1: Create Multipart Upload
      const createMultipartUploadCommand = new CreateMultipartUploadCommand({
        Bucket: bucketName,
        Key: key,
      });
      const createMultipartUploadResponse = await this._bucket.send(
        createMultipartUploadCommand,
      );
      uploadId = createMultipartUploadResponse.UploadId;

      for (let partNum = 0; partNum < numParts; partNum++) {
        const start = partNum * this._partSize;
        const end = Math.min(start + this._partSize, file.size);
        const partFile = file.slice(start, end);

        // Step 2: Upload each part
        const uploadPartCommand = new UploadPartCommand({
          Bucket: bucketName,
          Key: key,
          PartNumber: partNum + 1,
          UploadId: uploadId,
          Body: partFile,
        });
        const uploadPartResponse = await this._bucket.send(uploadPartCommand);
        multipartMap.Parts.push({
          ETag: uploadPartResponse.ETag,
          PartNumber: partNum + 1,
        });

        // Update progress
        this.uploadProgress[relativePath] = (100 * (partNum + 1)) / numParts;
        this.updateOverallProgress(); // Update overall progress after each file upload
      }

      // Step 3: Complete Multipart Upload
      const completeMultipartUploadCommand = new CompleteMultipartUploadCommand(
        {
          Bucket: bucketName,
          Key: key,
          UploadId: uploadId,
          MultipartUpload: multipartMap,
        },
      );
      await this._bucket.send(completeMultipartUploadCommand);

      console.log(`Upload of ${bucketName} ${key} completed successfully.`);
      resolve(); // Resolve the promise when the upload is complete
    } catch (err) {
      console.error(`Error uploading ${key}:`, err);
      if (uploadId) {
        // Optionally abort the multipart upload if something goes wrong
        await this.abortMultipartUpload(uploadId, key, bucketName);
      }
      reject(err); // Reject the promise if there's an error
    }
  }

  async abortMultipartUpload(
    uploadId: string,
    key: string,
    bucketName: string,
  ) {
    try {
      const abortMultipartUploadCommand = new AbortMultipartUploadCommand({
        Bucket: bucketName,
        Key: key,
        UploadId: uploadId,
      });
      await this._bucket.send(abortMultipartUploadCommand);
      console.log(`Aborted multipart upload for ${key}`);
    } catch (err) {
      console.error(`Error aborting multipart upload for ${key}:`, err);
    }
  }

  private calculateFileSize(ngxFile: NgxFileDropEntry): number {
    var fileSize = 0;
    const fileEntry = ngxFile.fileEntry as FileSystemFileEntry;
    // Use the `file` method to access the file information
    fileEntry.file((file: File) => {
      fileSize = file.size;
    });
    return fileSize;
  }
}
