import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';
import { UpdateAccountRequest } from '../models/update-account-request.model';
import { ResetPasswordAttemptRequest } from '../models/reset-password-attempt-request.model';
import { ResetPasswordProcessRequest } from '../models/reset-password-process-request.model';
import { AddAccountDetailsRequest } from '../models/add-account-details-request.model';
import { AdminAccountKpi } from '../models/admin-account-kpi.model';
import { AdminApplicationKpi } from '../models/admin-application-kpi.model';
import { AdminAccountDetailsKpi } from '../models/admin-account-details-kpi.model';

@Injectable({
    providedIn: 'root',
})
export class AdminService {
    constructor(private httpClient: HttpClient) {}

    public generateAccountKpi(idUser: string) {
        return this.httpClient.get<AdminAccountKpi>(`accounts/admin/${idUser}`);
    }

    public generateAccountDetailsKpi(idUser: string) {
        return this.httpClient.get<AdminAccountDetailsKpi>(`account_details/admin/${idUser}`);
    }

    public generateApplicationKpi(idUser: string) {
        return this.httpClient.get<AdminApplicationKpi>(`applications/admin/${idUser}`);
    }
}
