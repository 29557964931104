<!-- <app-application-navbar></app-application-navbar> -->
<div class="container mt-5">
  <div *ngIf="(application$ | async)" class="container-fluid mt--4">
    <div class="row justify-content-center">
      <div class="col-md-10">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="row">
              <div class="col">
                <h1 class="p-3">{{ 'overview.description' | translate }}</h1>
              </div>
            </div>

          </div>
          <div class="col-md-12">
            <div class="card card-stats mb-4 mb-xl-0">
              <div class="card-body">
                <div class="row d-flex">
                  <div class="col-md-6">
                    <h5 class="card-title text-uppercase text-muted mb-0">{{'billing.app_status'|translate}}</h5>
                    <span class="h2 font-weight-bold mb-0"> {{'billing.'+((application$ | async)?.state
                      )|translate}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-3">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <span *ngIf="(application$ | async)?.state=='UP'" class="badge badge-lg badge-success">
                      {{'billing.active'|translate}}
                    </span>
                    <span *ngIf="(application$ | async)?.state!='UP'" class="badge badge-lg badge-warning">
                      {{'billing.unactive'|translate}}
                    </span>
                  </div>
                  <div class="text-right">
                    <span>
                      <a class="btn btn-sm btn-secondary mx-0 m-1"
                        [routerLink]="['/dashboard/applications', (application$ | async).id , 'configuration']">
                        {{'overview.edit_app'|translate}}
                      </a>
                      <a class="btn btn-sm btn-primary mx-0 m-1" href="{{'https://' + (application$ | async).domain.url }}"
                        target="_blank">
                        {{'billing.open_app'|translate}}
                      </a>
                    </span>
                  </div>
                </div>
                <div class="my-4">
                  <span class="h6 surtitle text-primary">{{ 'billing.h7' | translate }}</span>
                  <h1>{{ (application$ | async).domain.url }}</h1>
                  <div class="my-4">
                    <span class="h6 surtitle text-primary">{{ 'billing.h11' | translate }}</span>
                    <h2>{{ (application$ | async).createdAt | date }}</h2>
                  </div>
                </div>
                <div class="my-4">
                  <span class="h6 surtitle text-primary">{{ 'billing.h5' | translate }}</span>
                  <h2>{{ (application$ | async).template?.name }}</h2>
                </div>
                <div class="my-4">
                  <span class="h6 surtitle text-primary">{{ 'billing.h6' | translate }}</span>
                  <h2>{{ (application$ | async).name }}</h2>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <h2 class="text-left mb-2">{{'cc.advanced' | translate}}</h2>
                <div class="d-flex flex-wrap justify-content-between align-items-center">
                  <div class="col-md-6">
                    <p>{{'cc.delete_app_message' | translate}}</p>
                  </div>
                  <div class="col-md-6 text-center text-md-right">
                    <button class="btn btn-white border-danger text-danger" type="button"
                    (click)="deleteApplication(application.id)">{{'cc.delete_permanently' | translate}}
                    </button>
                  </div>
                </div>
                <br>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
