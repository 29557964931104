import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationTemplateRequest } from 'src/app/models/application-template-request.model';
import { Application } from 'src/app/models/application.model';

@Component({
  selector: 'app-pi-function',
  templateUrl: './pi-function.component.html',
  styleUrls: ['./pi-function.component.scss']
})
export class PiFunctionComponent implements OnInit {
  @Input() application: Application;
  @Input() templateRequests:  ApplicationTemplateRequest[];
  @Input() getData : any;
  @Output() templateRequestsChange = new EventEmitter<boolean>();
  @ViewChild('requestsModal', { read: TemplateRef }) requestsModal:TemplateRef<any>;

  formModal: BsModalRef;
  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };
  selectTemplateModalSettings = {
    keyboard: true,
    class: "modal-xl"
  };

  selectedSubCategory: string;
  
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  getTemplateRequests(subCategory: string): ApplicationTemplateRequest[] {
    return this.templateRequests.filter(a => (a.templateRequest.subCategory === subCategory && a.templateRequest.pageBuilder));
  }

  openRequestsModal(subCategory: string) {
    this.selectedSubCategory = subCategory;
    this.formModal = this.modalService.show(this.requestsModal, this.selectTemplateModalSettings);
  }
}
