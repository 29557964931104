<div class="row">
  <div class="m-auto text-center" [ngClass]="{'col-6': application.template.pageBuilder, 'd-none': !application.template.pageBuilder}">
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header border-0">
            <h3 class="mb-0">{{'arc.button_page_builder'|translate}}</h3>
          </div>
          <div class="card-body">
            <a class="btn btn-secondary w-auto text-white" type="button" (click)="openDefaultModal(modalDefault)">
              {{'page_builder.button_title'|translate}}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="m-auto text-center" [ngClass]="{'col-6': application.template.pageBuilder, 'col-12': !application.template.pageBuilder}">
    <div class=" card">
      <div class=" card-header border-0">
        <h3 class=" mb-0">{{'arc.button_open_app'|translate}}</h3>
      </div>
      <div class="card-body">
        <a class="btn btn-primary w-auto text-white" type="button" href="https://{{applicationUrl}}"
          target="_blank">
          {{'billing.open_app'|translate}}
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template #modalDefault>
  <page-builder-header [pageBuilder]="this" [applicationName]="application.domain.name"></page-builder-header>
  <div>
    <app-pi-function *ngIf="application.template.name === 'piFunction'" [application]="application"
      [templateRequests]="templateRequests" (templateRequestsChange)="updateTemplateRequests()"></app-pi-function>
  </div>
  <div>
    <app-pi-transfer *ngIf="application.template.name === 'piTransfer'" [application]="application"
      [templateRequests]="templateRequests" (templateRequestsChange)="updateTemplateRequests()"></app-pi-transfer>
  </div>
  <div>
    <app-pi-profile *ngIf="application.template.name === 'piProfile'" [application]="application"
      [templateRequests]="templateRequests" (templateRequestsChange)="updateTemplateRequests()"></app-pi-profile>
  </div>
  <div>
    <app-pi-chat *ngIf="application.template.name === 'piChat'" [application]="application"
      [templateRequests]="templateRequests" (templateRequestsChange)="updateTemplateRequests()"></app-pi-chat>
  </div>
</ng-template>
