<div class="container-fluid mt--12">
  <div class="row justify-content-center">
    <div class="col-xl-12 my-6" >

      <app-applications-list-card></app-applications-list-card>
    </div>
  </div>
</div>



<div id="loading" class="d-flex align-items-center justify-content-center" *ngIf="isSubscribing">
  <span id="loading-image" class="loader" ></span>
</div>
