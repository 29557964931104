<nav
  class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-light bg-white"
  id="sidenav-main"
  (mouseover)="onMouseEnterSidenav()"
  (mouseout)="onMouseLeaveSidenav()"
  *ngIf="(application$ | async) as application"
>
  <div class="scrollbar-inner">
    <div class="sidenav-header d-flex align-items-center">
      <a class="navbar-brand nopointer">
       <!-- <img
         src="assets/img/logo/pirog.png"
         class="navbar-brand-img"
         alt="..."
       /> -->

        <div>
          <img src="assets/templates/{{application.template.name}}/text_color.png" style="height: 30px;">
        </div>
      </a>
      <div class="ml-auto">
        <!-- Sidenav toggler -->
        <div
          class="sidenav-toggler d-none d-xl-block"
          data-action="sidenav-unpin"
          data-target="#sidenav-main"
          (click)="minimizeSidebar()"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line {{ ' bg-' + application.template.name }}"></i>
            <i class="sidenav-toggler-line {{ ' bg-' + application.template.name }}"></i>
            <i class="sidenav-toggler-line {{ ' bg-' + application.template.name }}"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-inner">
      <div class="collapse navbar-collapse" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <ul class="navbar-nav">
          <li class="nav-item">
            <a [routerLink]="['/dashboard/applications', (application$ | async).id , 'overview']" type='link' class="nav-link" routerLinkActive="active">
              <i class="ni ni-world-2 text-{{application.template.name}}"></i>
              <span class="nav-link-text">{{'sac.h5'|translate }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/dashboard/applications', (application$ | async).id , 'configuration']" type='link' class="nav-link" routerLinkActive="active">
              <i class="ni ni-app text-{{application.template.name}}"></i>
              <span class="nav-link-text">{{'sac.h6'|translate }}</span>
            </a>
          </li>
          <li *ngFor="let menuitem of menuItems" class="nav-item">
            <!--If is a single link-->
            <a routerLinkActive="active" [routerLink]="['/dashboard/applications', (application$ | async).id , menuitem.path]"
              *ngIf="menuitem.type === 'link' && !menuitem.isPirogPro" class="nav-link">
              <i class="ni {{ menuitem.icontype + ' text-' + application.template.name }}"></i>
              <span class="nav-link-text">{{ menuitem.title }}</span>
            </a>
            <!--If is piAnalytics menu-->
            <a
            routerLinkActive="active"
            [routerLink]="['/dashboard/applications', (application$ | async).id , menuitem.path]"
            *ngIf="menuitem.type === 'link' && menuitem.isActivated"
            class="nav-link">
              <i class="ni {{ menuitem.icontype + ' text-' + application.template.name}}"></i>
              <span class="nav-link-text"> {{ menuitem.title|translate }}</span>
              <pirog-pro-button *ngIf="menuitem.isPirogPro" [enableText]="false" [modalMode]="false"></pirog-pro-button>
            </a>
            <!--If it have a submenu-->
            <a
              data-toggle="collapse"
              routerLinkActive="active"
              *ngIf="menuitem.type === 'sub'"
              (click)="menuitem.isCollapsed = !menuitem.isCollapsed"
              [attr.aria-expanded]="!menuitem.isCollapsed"
              [attr.aria-controls]="menuitem.collapse"
              class="nav-link"
            >
              <i class="ni {{ menuitem.icontype }}"></i>
              <span class="nav-link-text">{{ menuitem.title }}</span>
            </a>

            <!--Display the submenu items-->
            <div
              id="{{ menuitem.collapse }}"
              class="collapse"
              *ngIf="menuitem.type === 'sub'"
              [collapse]="menuitem.isCollapsed"
              [isAnimated]="true"
            >
              <ul class="nav nav-sm flex-column">
                <li
                  *ngFor="let childitems of menuitem.children"
                  class="nav-item"
                >
                  <!--If is a single link-->
                  <a
                    routerLinkActive="active"
                    [routerLink]="[menuitem.path, childitems.path]"
                    class="nav-link"
                    *ngIf="childitems.type === 'link'"
                  >
                    {{ childitems.title }}
                  </a>
                  <!--If it have a submenu-->
                  <a
                    data-toggle="collapse"
                    (click)="childitems.isCollapsed = !childitems.isCollapsed"
                    [attr.aria-expanded]="!childitems.isCollapsed"
                    [attr.aria-controls]="childitems.collapse"
                    *ngIf="childitems.type === 'sub'"
                    class="nav-link"
                  >
                    {{ childitems.title }}
                  </a>
                  <!--Display the submenu items-->
                  <div
                    id="{{ childitems.collapse }}"
                    class="collapse"
                    *ngIf="childitems.type === 'sub'"
                    [collapse]="childitems.isCollapsed"
                    [isAnimated]="true"
                  >
                    <ul class="nav">
                      <li
                        *ngFor="let childitem of childitems.children"
                        class="nav-item"
                      >
                        <a href="javascript:void(0)" class="nav-link">
                          {{ childitem.title }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <!-- Divider -->
        <hr class="my-3" />
        <h6 class="navbar-heading p-0 text-muted">{{'sac.h1'|translate}}</h6>
        <ul class="navbar-nav mb-md-3">
          <li class="nav-item">
            <a
              class="nav-link"
              href="{{'https://' + (application$ | async).domain.url }}" target="_blank">
              <i class="ni ni-curved-next text-primary"></i>
              <span class="nav-link-text">{{'sac.h4'|translate}}</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/order']"
              [queryParams]="{ name: (application$ | async).template.name }"
              target="_blank"
            >
              <i class="ni ni-fat-add text-primary"></i>
              <span class="nav-link-text">{{'sac.h2'|translate}} {{  (application$ | async).template.name}} </span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link "
              [routerLink]="['/doc/introduction']"
              target="_blank"
            >
            <i class="fa fa-question-circle text-primary text-primary"> </i>

              <span class="nav-link-text">
                {{'sac.h3'|translate}}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
