<div class="card">
  <div class="card-header">
    <h6 class="surtitle">{{ title }}</h6>

    <h5 class="h3 mb-0">{{ subTitle }}</h5>
  </div>

  <div class="card-body">
    <div class="chart">
      <canvas class="chart-canvas" id="bar-stacked"> </canvas>
    </div>
  </div>
</div>
