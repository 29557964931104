import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'pi-html-editor',
  templateUrl: './pi-html-editor.component.html',
  styleUrls: ['./pi-html-editor.component.scss']
})
export class PiHtmlEditorComponent implements OnInit {
  @Input() initialContent: string;
  @Input() styleUrl: string ="https://pifunction-3.dev.pirog.io/clients/style.css";

  @Output() submitHtmlOutput = new EventEmitter<string>();

  htmlContent: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '70vh',
    minHeight: '70vh',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: '',
    toolbarHiddenButtons: [
      [
        'fontName'
      ],
      [
        
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'toggleEditorMode',
        'removeFormat', 
     
      ]
    ]
  };
  
  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.buildInitialHtmlContent();
  }

  buildInitialHtmlContent() {
    var styleUrlLink = "";
    if(this.styleUrl !== null) {
      styleUrlLink = `<link rel="stylesheet" href="${this.styleUrl}">`
    }

    this.htmlContent = ` 
    <html>
      <title>W3.CSS</title>
      <meta name="viewport" content="width=device-width, initial-scale=1">
      <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
      <script src="https://cdn.jsdelivr.net/npm/popper.js@1.14.7/dist/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
      ${styleUrlLink}

      <body>
      ${this.initialContent}
      </body>
    </html>`
  }

  onSubmit() {
    this.submitHtmlOutput.emit(this.extractTextFromBody(this.htmlContent))
  }

  extractTextFromBody(htmlContent: string) {
    return htmlContent.substring(htmlContent.indexOf('<'))
  }
}
