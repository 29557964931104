import {Component, OnInit} from "@angular/core";
import {ROUTES} from "../dashboard-sidebar/dashboard-sidebar.component"
import {Router, Event} from '@angular/router';

import {Location} from "@angular/common";
import {Observable} from "rxjs";
import {User} from "src/app/models/user.model";
import {Store} from "@ngrx/store";
import {selectIsUserSubscribedToPirogPro, selectUser} from "src/app/store/auth/auth.selectors";
import {loadUserById, logout} from "src/app/store/auth/auth.actions";
import {environment} from "src/environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {PlatformService} from "src/app/services/plateform.service";

@Component({
  selector: "app-dashboard-navbar",
  templateUrl: "./dashboard-navbar.component.html",
  styleUrls: ["./dashboard-navbar.component.scss"]
})
export class DashboardNavbarComponent implements OnInit {

  public isSubscribedToPirogPro: boolean;
  public focus;
  public listTitles: any[];
  public location: Location;
  public isApplication: boolean;
  public isConsole: boolean;

  sidenavOpen: boolean = true;
  stripeUrl: string;
  user$: Observable<User>;
  currentLang = this.translate.store.currentLang;

  constructor(
    location: Location,
    private router: Router,
    private store: Store,
    private platformService: PlatformService,
    private translate: TranslateService) {

    this.location = location;
    this.stripeUrl = environment.stripePortalUrl;
    this.router.events.subscribe((event: Event) => {
      this.isApplication = this.router.url.includes("dashboard/applications/");
      this.isConsole = this.router.url.endsWith("dashboard/applications");
    });
  }

  ngOnInit() {
    this._userId();
    this.store.select(selectIsUserSubscribedToPirogPro).subscribe(x => {
      this.isSubscribedToPirogPro = x;
    });
    this.user$ = this.store.select(selectUser);
    this.listTitles = ROUTES.filter(listTitle => listTitle);
  }

  private _userId() {
    this.store.dispatch(loadUserById());
    //this._subscriptions.add(_userId);
  }

  logout() {
    this.store.dispatch(logout());
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === "#") {
      titlee = titlee.slice(1);
    }

    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    return "Dashboard";
  }

  openSearch() {
    if (this.platformService.isPlatformBrowser()) {
      document.body.classList.add("g-navbar-search-showing");
      setTimeout(function () {
        document.body.classList.remove("g-navbar-search-showing");
        document.body.classList.add("g-navbar-search-show");
      }, 150);
      setTimeout(function () {
        document.body.classList.add("g-navbar-search-shown");
      }, 300);
    }
  }

  closeSearch() {
    if (this.platformService.isPlatformBrowser()) {
      document.body.classList.remove("g-navbar-search-shown");
      setTimeout(function () {
        document.body.classList.remove("g-navbar-search-show");
        document.body.classList.add("g-navbar-search-hiding");
      }, 150);
      setTimeout(function () {
        document.body.classList.remove("g-navbar-search-hiding");
        document.body.classList.add("g-navbar-search-hidden");
      }, 300);
      setTimeout(function () {
        document.body.classList.remove("g-navbar-search-hidden");
      }, 500);
    }
  }

  openSidebar() {
    if (this.platformService.isPlatformBrowser()) {
      if (document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
        this.sidenavOpen = false;
      } else {
        document.body.classList.add("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-hidden");
        this.sidenavOpen = true;
      }
    }
  }

  toggleSidenav() {
    if (this.platformService.isPlatformBrowser()) {
      if (document.body.classList.contains("g-sidenav-pinned")) {
        document.body.classList.remove("g-sidenav-pinned");
        document.body.classList.add("g-sidenav-hidden");
        this.sidenavOpen = false;
      } else {
        document.body.classList.add("g-sidenav-pinned");
        document.body.classList.remove("g-sidenav-hidden");
        this.sidenavOpen = true;
      }
    }
  }
}
