import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs/internal/Observable';
import { clearCart, setTemplateType } from 'src/app/store/order/order.actions';

@Component({
  selector: 'app-pianalytics-kpi',
  templateUrl: './pianalytics-kpi.component.html',
  styleUrls: ['./pianalytics-kpi.component.scss'],
})
export class PiAnalyticsKpiComponent implements OnInit{
  @Input() input: any;
  constructor() {}

  ngOnInit(){
  }
}