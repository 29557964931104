<div id="cardfilesystem" class="card" *ngIf="platformeService.isPlatformBrowser()">
  <div class="card-header border-0">
    <div class="row">
      <div class="col-10">
        <h3 class="mb-0">{{'filesystem.main_title'|translate}}</h3>
        <h3>
          <small>{{'filesystem.fileUploadTitle'|translate}}: {{storageSize | number }} Gb</small>
          <small *ngIf="storageService.overallProgress!=0" class="text-warning">  {{'filesystem.fileUpload'|translate}}</small>
        </h3>
      </div>
    </div>
  </div>
  <div *ngIf="data" id="filesystem" class="row-filesystem overflow-auto" (click)="disableContextMenu()" oncontextmenu="return false;">
    <span *ngFor="let item of activesCol; index as i">
      <span id="col_{{ i }}" class="col-filesystem text-white p-2 bg-filesystem" >
        <div *ngFor="let fileOrFolder of subData(i);"(click)="onLeftClick($event, fileOrFolder)" (contextmenu)="onLeftAndRightClick($event, fileOrFolder)" >
          <div class ="d-flex align-items-center div-hover justify-content-around" [ngClass]="{'active': isActivated(fileOrFolder.id)}" (click)="toggleActivation(fileOrFolder.id)">
           <app-filesystem-folder *ngIf="basePath" [fileOrFolder]="fileOrFolder" [dropAndUpload]="dropAndUpload" [fileOver]="fileOver" [isActiv]="this.activesIds.includes(fileOrFolder.id)"></app-filesystem-folder>
           <i *ngIf="this.fileOrFolder.children.length>0" class="fas fa-ellipsis-v d-flex justify-content-center" (click)="onRightClick($event, fileOrFolder)"></i>
          </div>
        </div>
        <div [ngClass]="{'lastChild': i==0, 'NotLastChild': i!=0,'dragAndDropContainer': i==0}" >
          <ngx-file-drop dropZoneLabel="" (onFileDrop)="dropAndUpload($event)" (onFileOver)="fileOver($event, 'column')" dropZoneClassName="ngx-file-drop-style" contentClassName="ngx-file-drop-style">
            <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
              <div *ngIf="i==0" (click)="createEmptyDirectory()" class="d-flex align-items-center justify-content-center">
                <i class="folderIcon fa fa-regular fa fa-folder-plus" style="font-size: 30px;"></i>
              </div>
              <span id="{{ i }}"></span>
            </ng-template>
          </ngx-file-drop>
        </div>
      </span>
    </span>
    <div class="progress-absolute">
      <app-progress-bar [percent]="storageService.overallProgress"></app-progress-bar>
    </div>
  </div>
  <div *ngIf="contextmenu==true">
    <app-context-menu
      [x]="contextmenuX"
      [y]="contextmenuY"
      [applicationId]="application.id"
      [fileOrFolder]="fileOrFolderActif"
      [storageSize]="storageSize"
      [updateActiveCol]="updateActiveCol"
      [refresh]="refresh"
      [fireUsageAlert]="fireUsageAlert"
      [basePath]="basePath"
      [bucketName]="bucketName"
      [disableContextMenu]="disableContextMenu"
      [openFormModal]="openFormModal"
    ></app-context-menu>
  </div>
  <div *ngIf="data==undefined" id="filesystem" class="row-filesystem overflow-auto">
    <span class="d-flex col-12 col-nodata-filesystem text-white justify-content-center bg-primary-dark">
        <div class="col-6 justify-content-center align-item-center m-auto">
          <form [formGroup]="dragdropFg" (ngSubmit)="submitFirstDrop()" class="d-flex text-center flex-column">
            <ngx-file-drop dropZoneLabel="Drop files here" (onFileDrop)="firstDrop($event)">
                <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
                  <div class="dropFileZone text-white text-center">
                    {{'filesystem.dropzone_title'|translate}}
                    <br> {{'filesystem.or'|translate}} <br>
                    <button type="button" (click)="openFileSelector()">{{'filesystem.browse_files'|translate}}</button>
                  </div>
                </ng-template>
            </ngx-file-drop>
            <div class="mt-3">
              <button type="submit" class="btn btn-primary">{{'filesystem.upload'|translate}}</button>
            </div>
            <app-progress-bar [percent]="storageService.overallProgress"></app-progress-bar>
          </form>
        </div>
        <div *ngIf="storageService.filesNgx.length != 0" class="col-6 overflow-auto p-3" style="height: 250px;">
          <div class="upload-table overflow-auto h-100">
            <table class="table">
                <thead>
                    <tr class="text-white">
                        <th class="px-0">{{'filesystem.table_name'|translate}}</th>
                    </tr>
                </thead>
                <tbody class="upload-name-style text-white">
                    <tr *ngFor="let key of getKeys(storageService.uploadProgress); let i=index">
                        <!-- <td><strong>{{ item.relativePath }}</strong></td> -->
                        <td class="p-0">
                          <span class="d-inline-block text-left w-50">
                            {{ key }}
                          </span>
                          <span
                            class="d-inline-block text-right w-50"
                            [ngClass]="{'text-success': storageService.uploadProgress[key] === 100}">
                            {{ storageService.uploadProgress[key] | number:'1.0-0' }} %
                          </span>
                        </td>
                    </tr>
                </tbody>
            </table>
          </div>
        </div>
    </span>
  </div>
</div>

<!--
##################
#  SHARING MODAL #
##################
-->

<ng-template #modalForm>
  <div class="modal-body p-0">
    <div class="card bg-light border-0 mb-0">
      <div class="card-body px-lg-5 py-lg-3">
        <div *ngIf="!elementDTO?.isAParentShared; else elseBlock" class="row">
            <p class="w-100">{{'filesystem.share'|translate}} <strong>"{{fileOrFolderActif.key_decoded}}"</strong></p>
        </div>
        <ng-template #elseBlock >
          <div class="row">
            <p class="w-100"> <strong>"{{fileOrFolderActif.key_decoded}}"</strong> {{'share.isShared'| translate }}</p>
          </div>
        </ng-template>

        <div *ngIf="!elementDTO?.isAParentShared && !elementDTO?.isDirectlyPublicyShared" class="row">
          <form [formGroup]="userFileFg" (ngSubmit)="addUserFile() " class="w-100">
            <!-- <div class="form-group mb-1" >  -->
              <div class="input-group mb-3">
                <input
                  id="email"
                  class="form-control"
                  placeholder="{{'filesystem.add_email'|translate}}"
                  formControlName="email"
                  type="email"
                  name="email"
                  aria-describedby="button-add-email"
                />
                <div class="input-group-append">
                  <button class="btn btn-primary" type="submit" id="button-add-email">
                    <span class="btn-inner--icon">
                      <i class="fa fa-plus text-white" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>

              </div>
          </form>
        </div>
        <div *ngIf="elementDTO && !elementDTO?.isDirectlyPublicyShared">
          <div>
            <div class="row mt-2">
              <p> {{'filesystem.list_email'|translate}}</p>
            </div>
            <div class="row">
              <p *ngIf="!elementDTO.isItselfOrAParentPrivatelyShared && isAFolder">
                <small class="mr-4"><strong>{{'filesystem.info_email_folder'|translate}}</strong></small>
              </p>
              <p *ngIf="!elementDTO.isItselfOrAParentPrivatelyShared && !isAFolder">
                <small class="mr-4"><strong>{{'filesystem.info_email_file'|translate}}</strong></small>
              </p>
              <span *ngFor="let userFile of elementDTO.privateUserFiles">
                <span *ngIf="userFile.email" class="badge bg-white text-primary mr-2">{{ userFile.email }}
                  <a *ngIf="!elementDTO?.isAParentPrivatelyShared" class="text-primary" (click)="removeUserFile(userFile)">
                    <span><strong class="text-danger">x</strong></span>
                  </a>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="row mt-4">
          <p>{{'filesystem.access_title'|translate}}</p>
        </div>
        <div *ngIf="elementDTO?.isAParentShared" class="row">
          <span class="ml-1" ngClass="{{elementDTO?.isItselfOrAParentPublicyShared ? 'text-danger' : 'text-info' }}">{{elementDTO?.isItselfOrAParentPublicyShared ? ('filesystem.public'|translate): ('filesystem.private'|translate)}}</span>
        </div>
        <div *ngIf="!elementDTO?.isAParentShared" class="row">
          <div class="btn-group dropdown" dropdown>
            <button type="button" class="btn btn-white dropdown-toggle" ngClass="{{elementDTO?.isItselfOrAParentPublicyShared ? 'text-danger' : 'text-info' }}" dropdownToggle>{{elementDTO?.isItselfOrAParentPublicyShared ? ('filesystem.public'|translate): ('filesystem.private'|translate)}}</button>
            <div *dropdownMenu class="dropdown-menu">
              <a class="dropdown-item text-info" (click)="setUserFilesRestricted()">{{'filesystem.private'|translate}}</a>
              <a class="dropdown-item text-danger" (click)="setUserFilesPublic()">{{'filesystem.public'|translate}}</a>
            </div>
          </div>
        </div>
        <div *ngIf="!isAFolder" class="row d-flex justify-content-between mt-4">
          <!-- todo jk ang17

          ERROR] NG9: Property 'renameFileOrFolder' does not exist on type 'ApplicationFilesystemComponent'. [plugin angular-compiler]

    src/app/pages/admin/application/application-configuration/application-filesystem/application-filesystem.component.html:186:64:
      186 │ ...a fa-link" aria-hidden="true" (click)="renameFileOrFolder()"></i>
      -->
           <!-- <button type="button" (click)="handleGetObject(fileOrFolderActif)" class="btn btn-sm text-primary btn-white btn-round btn-icon table-action">
              <i class="fa fa-link" aria-hidden="true" (click)="renameFileOrFolder()"></i>
              {{'filesystem.copy_link'|translate}}
          </button>

           <a class="btn btn-sm btn-white btn-round btn-icon table-action m-0" (click)="handleGetObject(fileOrFolderActif)">
            <span class="btn-inner--text text-primary m-auto">
              <i class="fa fa-link" aria-hidden="true" (click)="renameFileOrFolder()"></i>
              {{'filesystem.copy_link'|translate}}
            </span>
          </a> -->
          <a class="btn btn-sm btn-primary btn-round btn-icon table-action" (click)="closeFormModal()">
            <span class="btn-inner--text text-white m-auto">
              {{'filesystem.close'|translate}}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<pirog-pro-modal #pirog_pro_modal></pirog-pro-modal>
