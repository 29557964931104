import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";
import {Store} from "@ngrx/store";
import {Observable, Subscription} from "rxjs";
import {Application} from "src/app/models/application.model";
import {SeoService} from "src/app/services/seo.service";
import {
  selectCurrentApplication,
  selectCurrentApplicationIsConfigComplete
} from "src/app/store/repository/repository.selector";


@Component({
  selector: "app-application-configuration",
  templateUrl: "application-configuration.component.html"
})
export class ApplicationConfigurationComponent implements OnInit {
  application$: Observable<Application>;
  isConfigComplete$: Observable<boolean>;

  subscriptions: Subscription = new Subscription();

  constructor(private store: Store, private router: Router,
              private seoService: SeoService) {
  }

  ngOnInit(): void {
    this.seoService.setSeoTags({
      titleKey: "seo.configure.title",
      descriptionKey: "seo.configure.description",
      noindex: true
    });

    this.initData();
  }

  private initData(): void {
    this.application$ = this.store.select(selectCurrentApplication);
    this.isConfigComplete$ = this.store.select(selectCurrentApplicationIsConfigComplete);
  }


}
