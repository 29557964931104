import {NgModule} from "@angular/core";
import {
  ApplicationConfigurationComponent
} from "./application/application-configuration/application-configuration.component";
import {ApplicationOverviewComponent} from "./application/application-overview/application-overview.component";
import {PiAnalyticsComponent} from "./application/pianalytics/pianalytics.component";
import {PublicOverviewComponent} from "./application/public-overview/public-overview.component";
import {PiAnalyticsKpiComponent} from "./application/pianalytics/pianalytics-kpi/pianalytics-kpi.component";
import {
  ApplicationTemplateRequestsComponent
} from "./application/application-configuration/application-template-requests/application-template-requests.component";
import {
  ApplicationRequestsComponent
} from "./application/application-configuration/application-requests/application-requests.component";
import {
  ApplicationRequestsTableComponent
} from "./application/application-requests-table/application-requests-table.component";
import {
  ApplicationFilesystemComponent
} from "./application/application-configuration/application-filesystem/application-filesystem.component";
import {InputsTableComponent} from "./application/application-configuration/inputs-table/inputs-table.component";
import {ApplicationNavbarComponent} from "./application/application-navbar/application-navbar.component";
import {PageBuilderComponent} from "./application/application-configuration/page-builder/page-builder.component";
import {
  RequestInputComponent
} from "./application/application-configuration/page-builder/request-input/request-input.component";
import {
  TemplateRequestsEditComponent
} from "./application/application-configuration/page-builder/template-request-input/template-requests-edit.component";
import {
  PiFunctionComponent
} from "./application/application-configuration/page-builder/template-interfaces/pi-function/pi-function.component";
import {
  PiTransferComponent
} from "./application/application-configuration/page-builder/template-interfaces/pi-transfer/pi-transfer.component";
import {
  PiProfileComponent
} from "./application/application-configuration/page-builder/template-interfaces/pi-profile/pi-profile.component";
import {
  PiChatComponent
} from "./application/application-configuration/page-builder/template-interfaces/pi-chat/pi-chat.component";
import {FilesystemFolderComponent} from "./application/filesystem-folder/filesystem-folder.component";
import {ContextMenuComponent} from "./application/context-menu/context-menu.component";
import {ProgressBarComponent} from "./application/progress-bar/progress-bar.component";
import {InputStatusComponent} from "./application/input-status/input-status.component";
import {PiHtmlEditorComponent} from "./application/application-configuration/pi-html-editor/pi-html-editor.component";
import {PageBuilderHeaderComponent} from "./application/page-builder-header/page-builder-header.component";
import {ApplicationsComponent} from "./applications/applications.component";
import {AccountComponent} from "./account/account.component";
import {ApplicationsListCardComponent} from "./applications/applications-list-card/applications-list-card.component";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxFileDropModule} from "ngx-file-drop";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {ModalModule} from "ngx-bootstrap/modal";
import {AlertModule} from "ngx-bootstrap/alert";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {RouterModule} from "@angular/router";
import {dashboardRoutes} from "./dashboard.routing";
import {DashboardComponent} from "./dashboard.component";
import {ApplicationLayoutComponent} from "./application/application-layout.component";
import {DashboardNavbarComponent} from "./dashboard-navbar/dashboard-navbar.component";
import {DashboardSidebarComponent} from "./dashboard-sidebar/dashboard-sidebar.component";
import {AdminComponent} from "./admin/admin.component";
import {CardComponent} from "./admin/card/card.component";
import {BarStackedComponent} from "./admin/bar-stacked/bar-stacked.component";
import {PieComponent} from "./admin/pie/pie.component";
import {SharedModule} from "../shared/shared.module";

@NgModule({
  declarations: [
    ApplicationConfigurationComponent,
    ApplicationOverviewComponent,
    PiAnalyticsComponent,
    PublicOverviewComponent,
    PiAnalyticsKpiComponent,
    ApplicationTemplateRequestsComponent,
    ApplicationRequestsComponent,
    ApplicationRequestsTableComponent,
    ApplicationFilesystemComponent,
    InputsTableComponent,
    ApplicationNavbarComponent,
    PageBuilderComponent,
    RequestInputComponent,
    TemplateRequestsEditComponent,
    PiFunctionComponent,
    PiTransferComponent,
    PiProfileComponent,
    PiChatComponent,
    FilesystemFolderComponent,
    ContextMenuComponent,
    ProgressBarComponent,
    InputStatusComponent,
    PiHtmlEditorComponent,
    PageBuilderHeaderComponent,
    ApplicationsComponent,
    AccountComponent,
    ApplicationsListCardComponent,
    DashboardComponent,
    ApplicationLayoutComponent,
    DashboardNavbarComponent,
    DashboardSidebarComponent,
    AdminComponent,
    CardComponent,
    BarStackedComponent,
    PieComponent
  ],
  exports: [
    DashboardNavbarComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFileDropModule,
    DragDropModule,
    ModalModule.forRoot(),
    AlertModule.forRoot(),
    CollapseModule.forRoot(),
    ProgressbarModule.forRoot(),
    TooltipModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDropdownModule.forRoot(),
    RouterModule.forChild(dashboardRoutes),
    SharedModule
  ]
})
export class DashboardModule {
}


