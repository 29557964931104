import { Component, Input } from '@angular/core';
import {PageBuilderComponent} from "../application-configuration/page-builder/page-builder.component";

@Component({
  selector: 'page-builder-header',
  templateUrl: './page-builder-header.component.html',
  styleUrls: ['./page-builder-header.component.scss']
})
export class PageBuilderHeaderComponent {
  @Input() pageBuilder: PageBuilderComponent;
  @Input() applicationName: string;
  closeDefaultModal() {
    this.pageBuilder.closeDefaultModal();
  }
}
