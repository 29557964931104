import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ApplicationRequestEntry } from 'src/app/models/application-request-entry.model';
import { ApplicationRequest, ApplicationRequests } from 'src/app/models/application-request.model';
import { Application } from 'src/app/models/application.model';
import { UpdateApplicationRequest } from 'src/app/models/update-application-request.model';
import { ApplicationService } from 'src/app/services/application.service';
import { moveItemInArray, CdkDragDrop } from '@angular/cdk/drag-drop';
import { UpdateApplicationRequestOrder, UpdateApplicationRequestsOrder } from 'src/app/models/update-application-requests-order.model';

@Component({
  selector: 'app-application-requests-table',
  templateUrl: './application-requests-table.component.html',
  styleUrls: ['./application-requests-table.component.scss']
})
export class ApplicationRequestsTableComponent implements OnInit {
  appRequests: ApplicationRequests;

  @Input() application: Application;
  @Input() operationType : String;
  appRequestFg: UntypedFormGroup;
  id: string;
  updateMode = false;
  placeholderTags: string[] = [];
  isChecked: boolean = false;
  isCheckedCategorical: boolean = false;
  data: ApplicationRequest[];

  @ViewChild('modalForm', { read: TemplateRef }) modalForm: TemplateRef<any>;
  formModal: BsModalRef;

  form = {
    keyboard: true,
    class: "modal-dialog-centered modal-sm"
  };

  requestTypes = [
    { label: "File", value: "FILE" },
    { label: "Text", value: "TEXT" },
    { label: "Number", value: "NUMBER" },
    { label: "Date", value: "DATE" },
    { label: "textarea", value: "TEXTAREA" },
    { label: "Boolean", value: "BOOLEAN" }
  ];

  requiredTypes = [
    { label: "Oui", value: "true" },
    { label: "Non", value: "false" }
  ];

  constructor(
    private applicationService: ApplicationService,
    private fb: UntypedFormBuilder,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.initForm();
    this.getData();
  }


  private getData(): void {
    this.applicationService
      .getApplicationRequestsByApplicationId(this.application.id)
      .subscribe((response) => {
        this.appRequests = response;
        if(this.operationType == 'INPUT'){
          this.data = response.inputs;
        } else  {
          this.data = response.outputs;
        }
      });
  }

  private initForm(): void {
    this.appRequestFg = this.fb.group({
      displayName: ["", Validators.required],
      placeholder: [""],
      name: ["", Validators.required],
      type: [this.requestTypes[1].value, Validators.required],
      operationType: ["", Validators.required],
      required: [false, Validators.required],
      categorical: [false],
      displayOrder: [1]
    });

    this.appRequestFg.get('placeholder').valueChanges.subscribe((value: string) => {
      this.placeholderTags = value.split(',').map(tag => tag.trim()).filter(tag => tag);
    });

  }

  onRequiredChange(event: Event): void {
    this.isChecked = (event.target as HTMLInputElement).checked;
  }

  onCategoricalChange(event: Event): void {
    this.isCheckedCategorical = (event.target as HTMLInputElement).checked;
    if (!this.isCheckedCategorical) {
      this.appRequestFg.patchValue({ placeholder: '' });
      this.placeholderTags = [];
    }
  }

  public openFormModal(): void {
    
    const operationType = this.operationType;
    this.updateMode = false;
    this.initForm();
    this.appRequestFg.patchValue({ operationType });
    this.formModal = this.modalService.show(this.modalForm, this.form);
  }

  public openUpdateFormModal(id: string): void {
    this.updateMode = true;

    const input = this.data.find(input => input.id == id);
    
    this.id = input.id;
    this.appRequestFg.patchValue(input);
    this.placeholderTags = input.placeholder.split(',').map(tag => tag.trim()).filter(tag => tag);

    this.formModal = this.modalService.show(this.modalForm, this.form);
  }

  public submit(): void {
    if (this.appRequestFg.valid) {

      if (this.updateMode) {
        const request: UpdateApplicationRequest = {
          id: this.id,
          displayName: this.appRequestFg.value.displayName,
          placeholder: this.appRequestFg.value.placeholder,
          name: this.appRequestFg.value.name,
          type: this.appRequestFg.value.type,
          required: this.appRequestFg.value.required,
          categorical: this.appRequestFg.value.categorical        
        };

        this.applicationService.updateApplicationRequest(request).subscribe(() => {
          this.modalService.hide();
          this.getData();
        });
      } else {
        const request: ApplicationRequestEntry = {
          displayName: this.appRequestFg.value.displayName,
          placeholder: this.appRequestFg.value.placeholder,
          name: this.appRequestFg.value.name,
          operationType: this.appRequestFg.value.operationType,
          type: this.appRequestFg.value.type,
          applicationId: this.application.id,
          required: this.appRequestFg.value.required,
          categorical: this.appRequestFg.value.categorical
        };

        this.applicationService.addApplicationRequest(request).subscribe(() => {
          this.modalService.hide();
          this.getData();
          
        });
      }
    }
  }

  public deleteAppRequest(idAppRequest: string): void {
    this.applicationService.deleteApplicationRequest(idAppRequest).subscribe(() => {
      this.getData();
    });
  }

  public addTag(tagInput: HTMLInputElement): void {
    const tag = tagInput.value.trim();
    if (tag && !this.placeholderTags.includes(tag)) {
      this.placeholderTags.push(tag);
      this.appRequestFg.patchValue({ placeholder: this.placeholderTags.join(',') });
    }
    tagInput.value = '';
  }

  public removeTag(tag: string): void {
    this.placeholderTags = this.placeholderTags.filter(t => t !== tag);
    this.appRequestFg.patchValue({ placeholder: this.placeholderTags.join(',') });
  }

  public onKeydown(event: KeyboardEvent, tagInput: HTMLInputElement): void {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.addTag(tagInput);
    }
  }
  
  async onDrop(event: CdkDragDrop<string[]>, data: any) {
    moveItemInArray(data, event.previousIndex, event.currentIndex);
    const request: UpdateApplicationRequestsOrder = {
      applicationRequestsOrder: []
    };

    const promises = data.map((input_or_output, idx) => {
      const updateApplicationRequestOrder: UpdateApplicationRequestOrder = {
          id: input_or_output.id,
          displayOrder: idx + 1
      };
      // Push to request.applicationRequestsOrder
      request.applicationRequestsOrder.push(updateApplicationRequestOrder);
  });

  // Wait for all the asynchronous operations to complete
  await Promise.all(promises);

  this.applicationService.updateApplicationRequestsOrder(request).subscribe((response) => {
    console.log('sorted!');
  });;
    
  }
}
