<div *ngIf="percent !== 0" class="progress-wrapper p-0">
    <div class="progress-light">
        <div class="progress-percentage"
            [ngClass]="{'text-success': percent == 100}">
            <span>{{ percent | number:'1.0-0'}}%</span>
        </div>
    </div>
    <div class="progress">
    <div 
        class="progress-bar bg-secondary" 
        role="progressbar" [attr.aria-valuenow]="percent" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{'width.%': percent }"></div>
    </div> 
</div>